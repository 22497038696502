import axios from 'axios';
import { addDays } from 'date-fns';

import { useBookingUrlGenerator } from '~/domains/booking';
import { extractDateFromApiString, formatDate } from '~/helpers';

import type {
  HotelByRidcodeResponse,
  ParsedRoomsAndGuestsParameter,
  RedirectQueryParameters,
  RedirectToBookingQueryParameters,
  RedirectToHotelQueryParameters,
  RedirectToSearchQueryParameters,
} from './redirect.interface';
import { RedirectType } from './redirect.interface';

export async function getRedirectType(
  serverUrl: string,
  query: RedirectQueryParameters,
): Promise<RedirectType | null> {
  if (query.ridcode) {
    if (!(await isRidcodeValid(serverUrl, query.ridcode))) {
      return null;
    }

    if (
      query.dateIn &&
      isDateInParamValid(query.dateIn) &&
      query.roomsAndGuests &&
      isRoomsAndGuestsParamValid(query.roomsAndGuests)
    ) {
      const availability = await hasAvailability(
        serverUrl,
        query.ridcode,
        query.dateIn,
        query.roomsAndGuests,
        query.nights,
      );

      if (availability) {
        return RedirectType.Booking;
      } else {
        return RedirectType.Search;
      }
    }

    return RedirectType.Hotel;
  } else if (query.to === 'booking-retry') {
    return RedirectType.BookingRetry;
  } else if (query.to === 'booking-confirmation') {
    return RedirectType.BookingConfirmation;
  } else if (query.to === 'continue-booking') {
    return RedirectType.ContinueBooking;
  } else if (query.to === 'booking-confirmation-error') {
    return RedirectType.BookingConfirmationError;
  }

  return RedirectType.Search;
}

export async function getRedirectUrl(
  redirectType: RedirectType | null,
  serverUrl: string,
  localePath: ReturnType<typeof useLocalePath>,
  bookingURL: ReturnType<typeof useBookingUrlGenerator>['bookingURL'],
  queryParameters: RedirectQueryParameters,
): Promise<string> {
  try {
    switch (redirectType) {
      case RedirectType.Booking:
        return getBookingRedirectUrl(
          bookingURL,
          queryParameters as RedirectToBookingQueryParameters,
        );

      case RedirectType.Hotel:
        return await getHotelRedirectUrl(
          serverUrl,
          localePath,
          queryParameters as RedirectToHotelQueryParameters,
        );

      case RedirectType.Search:
        return await getSearchRedirectUrl(
          serverUrl,
          localePath,
          queryParameters,
        );
      case RedirectType.BookingRetry:
        return localePath({
          name: 'booking-retry',
        });

      case RedirectType.ContinueBooking:
        return localePath({
          name: 'continue-booking',
        });

      case RedirectType.BookingConfirmationError:
        return localePath({
          name: 'booking-confirmation-error',
        });

      case RedirectType.BookingConfirmation:
        return localePath({
          name: 'booking-confirmation',
          query: {
            bookingId: queryParameters.bookingId,
            status: queryParameters.status,
          },
        });
      default:
        return '';
    }
  } catch (error) {
    return '';
  }
}

function getBookingRedirectUrl(
  bookingURL: ReturnType<typeof useBookingUrlGenerator>['bookingURL'],
  queryParameters: RedirectToBookingQueryParameters,
): string {
  let rooms: ParsedRoomsAndGuestsParameter[] = [];

  if (isRoomsAndGuestsParamValid(queryParameters.roomsAndGuests)) {
    rooms = JSON.parse(queryParameters.roomsAndGuests ?? '[]');
  }

  return bookingURL(
    rooms,
    queryParameters.ridcode,
    queryParameters.dateIn,
    queryParameters.nights ?? 1,
  );
}

async function getHotelRedirectUrl(
  serverUrl: string,
  localePath: ReturnType<typeof useLocalePath>,
  queryParameters: RedirectToHotelQueryParameters,
): Promise<string> {
  const response = await getHotelByRidcode(serverUrl, queryParameters.ridcode);

  if (!response || !response.exists) {
    throw new Error('Hotel not found');
  }

  const query: Record<string, string> = {};

  if (
    queryParameters.roomsAndGuests &&
    isRoomsAndGuestsParamValid(queryParameters.roomsAndGuests)
  ) {
    query.roomsAndGuests = queryParameters.roomsAndGuests;
  }

  return localePath({
    name: 'hotel-hotelSlug',
    params: { hotelSlug: response.hotel.slug },
    query,
  });
}

async function getSearchRedirectUrl(
  serverUrl: string,
  localePath: ReturnType<typeof useLocalePath>,
  queryParameters: RedirectToSearchQueryParameters,
): Promise<string> {
  const query: Record<string, string> = {};

  if (queryParameters.ridcode) {
    const response = await getHotelByRidcode(
      serverUrl,
      queryParameters.ridcode,
    );

    if (response && response.exists) {
      query.destination = JSON.stringify({
        text: response.hotel.name,
        type: 'HOTEL',
      });
    }
  } else if (
    queryParameters.destination &&
    isDestinationParamValid(queryParameters.destination)
  ) {
    query.destination = queryParameters.destination;
  }

  if (
    queryParameters.roomsAndGuests &&
    isRoomsAndGuestsParamValid(queryParameters.roomsAndGuests)
  ) {
    query.roomsAndGuests = queryParameters.roomsAndGuests;
  }

  if (queryParameters.dateIn && isDateInParamValid(queryParameters.dateIn)) {
    const dateIn = extractDateFromApiString(queryParameters.dateIn);

    if (dateIn) {
      const dateOut = addDays(dateIn, queryParameters.nights ?? 1);
      const dates = [
        formatDate(dateIn, 'yyyy-MM-dd'),
        formatDate(dateOut, 'yyyy-MM-dd'),
      ];

      query.dates = JSON.stringify(dates);
    }
  }
  return localePath({ name: 'search', query });
}

async function isRidcodeValid(
  serverUrl: string,
  ridcode: string,
): Promise<boolean> {
  const response = await getHotelByRidcode(serverUrl, ridcode);

  return response ? response.exists : false;
}

function isDateInParamValid(dateIn: string): boolean {
  return !Number.isNaN(Date.parse(dateIn));
}

function isDestinationParamValid(destination: string): boolean {
  try {
    const parsedDestination = JSON.parse(destination);

    return (
      typeof parsedDestination === 'object' &&
      typeof parsedDestination.text === 'string' &&
      typeof parsedDestination.type === 'string'
    );
  } catch (error) {
    return false;
  }
}

function isRoomsAndGuestsParamValid(roomsAndGuests: string): boolean {
  try {
    const roomsParams = JSON.parse(roomsAndGuests);

    if (!Array.isArray(roomsParams)) {
      return false;
    }

    return roomsParams.every(
      (room: Record<string, unknown>) =>
        typeof room.adults === 'number' &&
        Array.isArray(room.childrenAges) &&
        room.childrenAges.every((age: unknown) => typeof age === 'number'),
    );
  } catch (error) {
    return false;
  }
}

async function hasAvailability(
  serverUrl: string,
  ridcode: string,
  dateIn: string,
  roomsAndGuests: string,
  nights?: number,
): Promise<boolean> {
  const roomsParams = JSON.parse(
    roomsAndGuests ?? '[]',
  ) as ParsedRoomsAndGuestsParameter[];

  try {
    const response = await axios.get(`${serverUrl}/hotels/has-availability`, {
      params: {
        ridcode,
        dateIn,
        nights: nights ?? 1,
        adults: roomsParams[0].adults,
        childrenAges: roomsParams[0].childrenAges,
      },
    });

    return response.data;
  } catch (error) {
    return false;
  }
}

async function getHotelByRidcode(
  serverUrl: string,
  ridcode: string,
): Promise<HotelByRidcodeResponse | null> {
  try {
    const response = await axios.get(`${serverUrl}/hotels/by-ridcode`, {
      params: { ridcode },
    });

    return response.data;
  } catch (error) {
    return null;
  }
}
