/* eslint @typescript-eslint/ban-ts-comment: 0 */
import type { LocationQuery } from 'vue-router';

import type {
  RawBookingQueryParameters,
  Room,
  UnconfiguredGuests,
} from './interfaces';

export const serializeRemainingGuests = (
  remainingGuests: Array<UnconfiguredGuests>,
  parameters?: RawBookingQueryParameters,
) => {
  const serialization = remainingGuests
    .map(guests => {
      let composite = guests.adults.toString();

      if (guests.childrenAges.length) {
        composite += '-' + guests.childrenAges.join('-');
      }

      return composite;
    })
    .join(',');

  if (parameters) {
    parameters.remainingGuests = serialization;
  }

  return serialization;
};

export const unserializeRemainingGuests = (
  query: LocationQuery,
): Array<UnconfiguredGuests> => {
  if (!query.remainingGuests) return [];

  const guests = (query.remainingGuests as string).split(',');

  return guests.map(guest => {
    const [adults, ...childrenAges] = guest.split('-');

    return {
      adults: parseInt(adults),
      childrenAges: childrenAges.map(age => parseInt(age)),
    };
  });
};

export const serializeSpecialOptions = (
  options: string[],
  parameters?: RawBookingQueryParameters,
) => {
  const serialization = options.join('-');

  if (parameters) {
    parameters.specialOptions = serialization;
  }

  return serialization;
};

export const unserializeSpecialOptions = (query: LocationQuery) => {
  if (!query.specialOptions) return null;

  return (query.specialOptions as string).split('-');
};

export const serializeCurrentRoom = (
  currentRoom: Partial<Room>,
  parameters: RawBookingQueryParameters,
) => {
  for (const key in currentRoom) {
    if (key === 'basketRoomId') continue;

    // @ts-ignore
    parameters['current-' + key] = currentRoom[key];
  }
};

export const unserializeCurrentRoom = (query: LocationQuery) => {
  const currentRoom = {};
  for (const key in query) {
    if (key.includes('current')) {
      const [, ...restOfKey] = key.split('-');

      const rebuiltKey = restOfKey.join('-');

      // @ts-ignore
      currentRoom[rebuiltKey] = query[key];
    }
  }

  return currentRoom;
};

export const serializeRooms = (
  rooms: Room[],
  parameters: RawBookingQueryParameters,
) => {
  rooms.forEach((room, index) => {
    const filteredRoom = JSON.parse(JSON.stringify(room));

    delete filteredRoom.publicPrice;
    delete filteredRoom.memberPrice;
    delete filteredRoom.basketRoomId;

    for (const key in filteredRoom) {
      let value = filteredRoom[key];
      if (key === 'childrenAges') {
        if (value.length) {
          value = value.join('-');
        } else {
          value = null;
        }
      } else if (key === 'options') {
        const options = Array.from(room.options || []);

        const composite = [];
        for (const [, value] of options) {
          composite.push(`${value.code}-${value.quantity}-${value.nights}`);
        }

        value = composite.join(',');
      }

      // @ts-ignore
      parameters[`room${index}-${key}`] = value;
    }
  });
};

export const unserializeRooms = (query: LocationQuery): Room[] => {
  const sortedRooms = {} as Record<string, any>;
  const regex = /room\d-.*/;
  for (const key in query) {
    if (regex.test(key)) {
      const [, ...restOfInformations] = key.split('room');
      const informations = restOfInformations.join('room');

      const [roomIndex, ...restOfRoomKey] = informations.split('-');

      const rebuiltKey = restOfRoomKey.join('-');

      if (!sortedRooms[roomIndex]) {
        sortedRooms[roomIndex] = {};
      }

      sortedRooms[roomIndex][rebuiltKey] = query[key];
    }
  }

  return rebuildRooms(sortedRooms);
};

const rebuildRooms = (sortedRooms: Record<string, any>) => {
  const rooms = [] as Room[];
  Object.keys(sortedRooms)
    .sort()
    .forEach(roomIndex => {
      const room = {} as Room;

      Object.keys(sortedRooms[roomIndex]).forEach(property => {
        let value = sortedRooms[roomIndex][property];

        if (property === 'options') {
          const options = (value as string).split(',');
          options.forEach(option => {
            const [code, quantity, nights] = option.split('-');

            if (!room.options) {
              room.options = new Map();
            }

            room.options.set(code, {
              code,
              quantity: parseInt(quantity),
              nights: parseInt(nights),
              type: '',
              name: '',
            });
          });

          return;
        } else if (property === 'childrenAges') {
          if (value === null) {
            value = [];
          } else {
            value = value.split('-').map(Number);
          }
        } else if (property === 'adults') {
          value = parseInt(value);
        }
        // @ts-ignore
        room[property] = value;
      });

      rooms.push(room);
    });

  return rooms;
};
