export const computeAdultsAndChildren = (
  adults?: number,
  childrenAges?: number[],
  maxChildAge?: number,
) => {
  const totalAdults = adults ?? 2;
  let childAsAdults = 0;
  const childrens = [] as number[];

  if (Array.isArray(childrenAges)) {
    childrenAges.forEach(childrenAge => {
      if (typeof maxChildAge === 'number' && childrenAge > maxChildAge) {
        childAsAdults++;
      } else {
        childrens.push(childrenAge);
      }
    });
  }

  return { adults: totalAdults + childAsAdults, childrenAges: childrens };
};
