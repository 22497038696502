<template>
  <div
    class="AccountSubmenuNotLogged"
    :class="{ 'AccountSubmenuNotLogged--inverse': inverse }">
    <div class="AccountSubmenuNotLogged-content">
      <button
        v-if="!hideClose"
        class="AccountSubmenuNotLogged-closeButton"
        :aria-label="$t('ux.molecules.modal.close.label')"
        @click="$emit('close')">
        <ai-icon
          name="close"
          class="AccountSubmenuNotLogged-closeButtonIcon"
          :class="{
            'AccountSubmenuNotLogged-closeButtonIcon--inverse': inverse,
          }" />
      </button>

      <ai-typo
        class="AccountSubmenuNotLogged-title"
        variant="heading-04"
        as="h4"
        :color="inverse ? 'white' : 'stratos-900'">
        {{ $t('accountSubmenu.title') }}
      </ai-typo>

      <ai-typo
        class="AccountSubmenuNotLogged-description"
        variant="legal-regular"
        as="p"
        :color="inverse ? 'neutral-100' : 'stratos-800'">
        {{ $t('accountSubmenu.description') }}
      </ai-typo>

      <ai-button
        data-testid="myAccountLoginButton"
        class="AccountSubmenuNotLogged-login"
        as-link
        :url="loginUrl"
        :inverse="inverse"
        :label="$t('accountSubmenu.login')"
        :full-width="true" />

      <ai-button
        class="AccountSubmenuNotLogged-createAccount"
        as-link
        :url="signupUrl"
        :inverse="inverse"
        variant="secondary"
        :label="$t('accountSubmenu.createAccount')"
        :full-width="true" />

      <ai-typo
        class="AccountSubmenuNotLogged-benefitsDescription"
        variant="paragraph-04-regular"
        as="p"
        :color="inverse ? 'neutral-100' : 'stratos-800'">
        {{ $t('accountSubmenu.benefitsDescription') }}
      </ai-typo>

      <ai-link
        :color="inverse ? 'gold-300' : 'gold-900'"
        target="_blank"
        typo-variant="navigation-link"
        :text="$t('accountSubmenu.benefitsLearnMore')"
        :url="benefitsLearnMoreUrl"
        undecorated />
    </div>
    <div class="AccountSubmenuNotLogged-cta">
      <ai-button
        :inverse="inverse"
        variant="tertiary"
        :label="$t('accountSubmenu.findMyBooking')"
        full-width
        as-link
        target="_blank"
        :url="findMyBookingUrl" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getRedirectUrl } from '~~/domains/ux/helpers/loyaltyBanner';

type Props = {
  inverse?: boolean;
  hideClose?: boolean;
};

type Emits = {
  (event: 'close'): void;
};

defineProps<Props>();
defineEmits<Emits>();

const config = useRuntimeConfig();
const { locale } = useI18n();
const route = useRoute();

const benefitsLearnMoreUrl = computed(
  () =>
    `${config.public.allBaseUrl}/loyalty-program/reasonstojoin/index.${locale.value}.shtml`,
);
const findMyBookingUrl = computed(
  () =>
    `${config.public.allBaseUrl}/managebookings/index.${locale.value}.shtml?#/bookings/home`,
);

const loginUrl = computed(() =>
  getRedirectUrl(
    config.public.accorAuthenticationUrl,
    config.public.baseUrl,
    locale.value,
    {
      redirect: route.fullPath,
      type: 'login',
    },
  ).toString(),
);

const signupUrl = computed(() =>
  getRedirectUrl(
    config.public.accorAuthenticationUrl,
    config.public.baseUrl,
    locale.value,
    { redirect: route.fullPath, type: 'signup' },
    true,
  ).toString(),
);
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/functions' as func;

.AccountSubmenuNotLogged {
  background-color: colors.$white;
  border: 1px solid colors.$gold-200;

  &--inverse {
    background-color: colors.$neutral-800;
  }
}
.AccountSubmenuNotLogged-content {
  position: relative;
  padding: constants.$padding-03 constants.$padding-02;

  @media (mq.$from-medium) {
    min-width: func.calcRem(360);
  }
}

.AccountSubmenuNotLogged-closeButton {
  position: absolute;
  right: constants.$margin-02;
  top: constants.$margin-02;
  fill: colors.$gold-300;
}

.AccountSubmenuNotLogged-closeButtonIcon {
  fill: colors.$gold-900;

  &--inverse {
    fill: colors.$gold-300;
  }
}

.AccountSubmenuNotLogged-description {
  margin-bottom: constants.$margin-02;
}

.AccountSubmenuNotLogged-login {
  margin-bottom: constants.$margin-01;
}

.AccountSubmenuNotLogged-createAccount {
  margin-bottom: constants.$margin-03;
}

.AccountSubmenuNotLogged-benefitsDescription {
  margin-bottom: constants.$margin-01;
}

.AccountSubmenuNotLogged-cta {
  padding: constants.$padding-01;

  border-top: 1px solid colors.$gold-200;
}
</style>
