const HEADERS_TO_KEEP = ['cloudfront-viewer-country', 'x-debug-viewer-country'];

// Expose SSR headers through nuxtApp.payload.data.headers
export default defineNuxtPlugin(nuxtApp => {
  if (!process.server) return;

  const requestHeaders = useRequestHeaders();
  nuxtApp.payload.data.headers = {};

  for (const header of HEADERS_TO_KEEP) {
    if (header in requestHeaders) {
      nuxtApp.payload.data.headers[header] = requestHeaders[header];
    }
  }
});
