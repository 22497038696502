export const focusableElementsSelector =
  'button, [href]:not(use), input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const useFocusTrap = () => {
  let $firstFocusable: HTMLElement;
  let $lastFocusable: HTMLElement;
  const trapRef = customRef((track, trigger) => {
    let $trapEl: HTMLElement;
    return {
      get() {
        track();
        return $trapEl;
      },
      set(value: HTMLElement) {
        $trapEl = value;
        value ? initFocusTrap() : clearFocusTrap();
        trigger();
      },
    };
  });

  function keyHandler(e: {
    key: string;
    shiftKey: string | any;
    preventDefault: () => void;
  }) {
    const isTabPressed = e.key === 'Tab';

    if (!isTabPressed) return;

    if (e.shiftKey) {
      if (document.activeElement === $firstFocusable) {
        $lastFocusable.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement !== $lastFocusable) {
        return;
      }
      $firstFocusable.focus();
      e.preventDefault();
    }
  }

  function initFocusTrap() {
    if (!trapRef.value) return;
    const focusableElements = trapRef.value.querySelectorAll<HTMLElement>(
      focusableElementsSelector,
    );
    $firstFocusable = focusableElements[0];
    $lastFocusable = focusableElements[focusableElements.length - 1];
    document.addEventListener('keydown', keyHandler);

    const activeElement = document.activeElement;
    if (
      activeElement?.tagName?.toLowerCase?.() !== 'input' &&
      !activeElement?.classList.contains('AiButton--positive') &&
      $firstFocusable
    ) {
      nextTick(() => {
        $firstFocusable.focus();
      });
    }
  }

  function clearFocusTrap() {
    if (!trapRef.value) return;
    document.removeEventListener('keydown', keyHandler);
  }

  return {
    clearFocusTrap,
    initFocusTrap,
    trapRef,
  };
};
