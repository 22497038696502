import { decodeJwt } from 'jose';

import { parseRawCookie } from '~/helpers';

import { SESSION_STORAGE_KEYS } from '~~/core';
import { useBookingStore } from '~~/domains/booking';
import { useIdentificationPlainTextQuery } from '~~/domains/graphql';
import { useUserStore } from '~~/domains/user';

type IdentificationToken = {
  identification: {
    promotional: {
      preferentialCode: string;
    };
  };
};

const IDENTIFICATION_TOKEN_KEY = 'identification-token';

export function useHasIdentificationCookies() {
  const requestEvent = useRequestEvent();

  const getCookie = (name: string) => {
    const cookies = parseRawCookie(requestEvent);
    return cookies[name];
  };

  const hasCookies = ref(false);
  const timer = ref<NodeJS.Timeout>();

  if (process.client) {
    const hw2h = getCookie('_Hw2h_');
    const jsessionId = getCookie('JSESSIONID');
    const org = getCookie('org');
    const identification = getCookie('identification_com.allinclusive');
    const idToken = getCookie(IDENTIFICATION_TOKEN_KEY);

    hasCookies.value = Boolean(
      hw2h && jsessionId && org && identification && idToken,
    );

    if (!hasCookies.value) {
      timer.value = setInterval(() => {
        const hw2h = getCookie('_Hw2h_');
        const jsessionId = getCookie('JSESSIONID');
        const org = getCookie('org');
        const identification = getCookie('identification_com.allinclusive');
        const idToken = getCookie(IDENTIFICATION_TOKEN_KEY);

        const currentHasCookie = Boolean(
          hw2h && jsessionId && org && identification && idToken,
        );

        if (currentHasCookie !== hasCookies.value) {
          hasCookies.value = currentHasCookie;

          if (currentHasCookie && timer.value) {
            clearInterval(timer.value);
          }
        }
      }, 500);
    }
  }

  return computed(() => hasCookies.value);
}

export function useIdentificationPlaintext() {
  const userStore = useUserStore();
  const bookingStore = useBookingStore();
  const preferentialCode = usePreferentialCode();

  const promotionalInformations = computed(() => ({
    cardNumber: userStore.loyaltyCardNumber,
    preferentialCode,
    ...bookingStore.promotionalInformation,
  }));

  const isIdentified = useHasIdentificationCookies();
  const enabled = computed(() =>
    Boolean(!isIdentified.value && process.client),
  );

  const { onResult, onError } = useIdentificationPlainTextQuery(
    promotionalInformations,
    {
      fetchPolicy: 'no-cache',
      enabled,
    },
  );

  onResult(async result => {
    if (result.loading) return;

    await userStore.loadUser();

    Object.values(SESSION_STORAGE_KEYS).forEach(value => {
      if (value.includes('booking::')) {
        window.sessionStorage.removeItem(value);
      }
    });
  });

  onError(error => {
    // eslint-disable-next-line no-console
    console.error(error);
  });
}

export function usePreferentialCode() {
  const requestEvent = useRequestEvent();
  const route = useRoute();

  const getCookie = (name: string) => {
    const cookies = parseRawCookie(requestEvent);
    return cookies[name];
  };

  const identificationToken = getCookie(IDENTIFICATION_TOKEN_KEY);
  const decodedToken = identificationToken
    ? (decodeJwt(identificationToken) as IdentificationToken)
    : null;
  const cookiePreferentialCode =
    decodedToken?.identification?.promotional?.preferentialCode;

  const rawPreferentialCode = route.query.preferentialCode as string;

  // We need to split the query parameter to get the preferential code because
  // each time you login, the query parameter "?type=login" is added to the URL but not removed when you logout
  const routePreferentialCode = rawPreferentialCode?.split('?')[0] ?? '';

  return cookiePreferentialCode ?? routePreferentialCode;
}
