function setContentfulPreviewHeaders() {
  if (!process.server) return;

  const request = useRequestEvent();

  request?.node.res.setHeader(
    'Content-Security-Policy',
    'frame-ancestors https://app.contentful.com',
  );
}

/**
 * Redirect the user coming from contentful preview
 * Because CF redirect the user to <language-COUNTRY> and
 * the platform support only <language>
 */
function setContentfulLanguageRedirection() {
  if (!process.server) return;

  const request = useRequestEvent();

  const localeMatch = /^\/?([a-z]{2})-([A-Z]{2})\//.exec(request?.path ?? '');

  if (!localeMatch) return;

  const [, language] = Array.from(localeMatch.values());

  const newPath = request?.path.replace(
    /^\/?([a-z]{2})-([A-Z]{2})\//,
    `/${language}/`,
  );

  navigateTo(newPath);
}

export default defineNuxtPlugin(() => {
  setContentfulPreviewHeaders();
  setContentfulLanguageRedirection();
});
