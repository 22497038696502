<template>
  <footer class="AiFooter">
    <div
      class="AiFooter-topPart"
      :class="{
        'AiFooter-topPart--withExtraColumns': withExtraColumn,
        'AiFooter-topPart--withoutExtraColumns': !withExtraColumn,
      }">
      <nuxt-link :to="localePath({ name: 'index' })">
        <ai-logo brand="AAI" variant="inverse" />
      </nuxt-link>

      <div
        class="AiFooter-columnsContainer"
        :class="{
          'AiFooter-columnsContainer--withExtraColumns': withExtraColumn,
          'AiFooter-columnsContainer--withoutExtraColumns': !withExtraColumn,
        }">
        <nav
          v-for="column in columns"
          :key="column.id"
          :aria-label="column.title">
          <ai-typo
            class="AiFooter-columnTitle"
            as="h2"
            variant="overline-01"
            color="white">
            {{ column.title }}
          </ai-typo>
          <ul class="AiFooter-columnList">
            <li v-for="link in column.links" :key="link.id">
              <ai-link
                class="AiFooter-columnLink"
                :text="link.label"
                :url="link.url"
                :target="getTarget(link.url)"
                typo-variant="navigation-link"
                color="white"
                undecorated />
            </li>
          </ul>
        </nav>

        <nav
          v-if="socialMedia.length"
          :aria-label="$t('footer.title.followUs')">
          <ai-typo
            class="AiFooter-columnTitle"
            as="h2"
            variant="overline-01"
            color="white">
            {{ $t('footer.title.followUs') }}
          </ai-typo>
          <ul class="AiFooter-columnGrid">
            <template
              v-for="socialMediaLink in socialMedia"
              :key="socialMediaLink.id">
              <li v-if="socialMediaLink.icon?.src">
                <ai-link
                  :url="socialMediaLink.url"
                  color="white"
                  undecorated
                  target="_blank"
                  :aria-label="
                    $t('accessibility.description.linkNewWindow', {
                      title: socialMediaLink.icon.alt,
                    })
                  "
                  @click="sendSocialNetworksEvent(socialMediaLink.label)">
                  <ai-external-icon :url="socialMediaLink.icon.src" />
                </ai-link>
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </div>

    <div v-if="brandLogos.length">
      <ai-separator color="stratos" :margin="2" />

      <ul
        class="AiFooter-brandLogosContainer"
        :aria-label="$t('footer.brands')">
        <li
          v-for="logo in brandLogos"
          :key="logo.id as string"
          class="AiFooter-BrandLogo">
          <ai-image
            :src="logo.src as string"
            :src-set="logo.srcSet as string"
            alt="" />
        </li>
      </ul>
    </div>

    <ai-separator color="stratos" :margin="2" />

    <div class="AiFooter-bottomPart">
      <ul>
        <li v-for="link in links" :key="link.id">
          <ai-link
            :url="link.url"
            :text="link.label"
            :target="getTarget(link.url)"
            typo-variant="paragraph-04-regular"
            color="white"
            undecorated />
        </li>
      </ul>

      <ai-typo as="p" variant="paragraph-04-regular" color="neutral-300">
        &#169; {{ copyright }}
      </ai-typo>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type {
  AiPicture,
  ContactUsFragment,
  FooterColumnFragment,
  FooterIconLinkFragment,
  FooterTextLinkFragment,
} from '~/domains/graphql';
import { event } from '~/helpers/gtm.helper';

interface Props {
  columns?: FooterColumnFragment[];
  contactUs?: ContactUsFragment;
  copyright: string;
  brandLogos?: AiPicture[];
  links?: FooterTextLinkFragment[];
  socialMedia?: FooterIconLinkFragment[];
}

const props = withDefaults(defineProps<Props>(), {
  brandLogos: () => [],
  columns: () => [],
  contactUs: undefined,
  links: () => [],
  socialMedia: () => [],
});

const config = useRuntimeConfig();

const extraColumnCount = computed(() => {
  return props.columns.length ?? 0;
});
const withExtraColumn = computed(() => {
  return extraColumnCount.value > 0;
});

const getTarget = (url: string) => {
  const targetUrl = new URL(url);
  const baseUrl = new URL(config.public.baseUrl);

  const isInternal = targetUrl.origin === baseUrl.origin;

  return isInternal ? '_self' : '_blank';
};

const sendSocialNetworksEvent = (socialNetwork: string) => {
  event('social_networks', {
    event_name: 'follow',
    category: 'social networks',
    action: 'click',
    label: 'follow us',
    content_type: '',
    network_name: socialNetwork,
  });
};
</script>

<style lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/mq';

.AiFooter {
  background: constants.$linear-gradient-stratos;
  padding: constants.$padding-02;

  @media (mq.$from-large) {
    padding: constants.$padding-02 constants.$padding-05;
  }
}

.AiFooter-nav {
  @media (mq.$from-large) {
    max-width: 60%;
  }
}

.AiFooter-topPart {
  display: flex;
  margin-bottom: constants.$margin-03;

  &--withExtraColumns {
    flex-direction: column;
    gap: constants.$margin-03;
  }

  &--withoutExtraColumns {
    flex-direction: row;
    gap: constants.$margin-07;
  }
}

.AiFooter-columnsContainer {
  display: flex;
  flex-direction: column;
  gap: constants.$margin-03;

  @media (mq.$from-large) {
    &--withExtraColumns {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(v-bind(extraColumnCount), 1fr) 2fr min-content;
      grid-gap: constants.$inner-03;
    }

    &--withoutExtraColumns {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      grid-gap: constants.$inner-03;
    }
  }
}

.AiFooter-columnList {
  li:not(:last-child) {
    margin-bottom: constants.$inner-03;
  }
}

.AiFooter-columnGrid {
  width: min-content;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-top: solid 1px colors.$stratos-500;
  border-left: solid 1px colors.$stratos-500;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px colors.$stratos-500;
    border-right: solid 1px colors.$stratos-500;

    @include mixins.rem-fallback(height, 48);
    @include mixins.rem-fallback(width, 48);
  }
}

.AiFooter-columnLink {
  width: max-content;
}

.AiFooter-columnLinkDescription {
  color: #c4c3cf;
  @include mixins.rem-fallback(font-size, 12);
}

.AiFooter-columnTitle {
  opacity: 0.75;
  text-transform: uppercase;
  margin-bottom: constants.$inner-03;
}

.AiFooter-brandLogosContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: constants.$margin-03;
  flex-wrap: wrap;
}

.AiFooter-bottomPart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: constants.$margin-02;

  @media (mq.$from-large) {
    flex-direction: row;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: constants.$margin-00;

    @media (mq.$from-large) {
      flex-direction: row;
      gap: constants.$margin-03;
    }
  }
}

.AiFooter-BrandLogo > picture > img {
  max-width: 100px;
}

.AiFooter-phoneContact-label {
  display: inline-block;
  margin-right: constants.$inner-02;
  font-weight: 500;
}
</style>
