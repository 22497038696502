import { defineStore } from 'pinia';
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

export type State = {
  isScrollLocked: boolean;
  pauseSkeletons: boolean;
  header: {
    routeLocalization: Record<string, RouteLocationRaw>;
    queryParameterLocalization:
      | ((
          route: Partial<RouteLocationNormalized>,
          locale: string,
        ) => Partial<RouteLocationNormalized> | undefined | void)
      | undefined;
  };
};

type Getters = Record<string, never>;

type Actions = {
  setScrollLocked(value: boolean): void;
};

export const useLayoutStore = defineStore<'layout', State, Getters, Actions>(
  'layout',
  {
    actions: {
      setScrollLocked(value: boolean) {
        this.isScrollLocked = value;
      },
    },
    state: () => ({
      header: {
        routeLocalization: {},
        queryParameterLocalization: undefined,
      },
      isScrollLocked: false,
      pauseSkeletons: false,
    }),
  },
);
