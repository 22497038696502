<template>
  <ai-drawer v-model="open" content-align="top">
    <!-- Header -->
    <template #header="slotProps">
      <div class="AiHeaderBurgerMenu-header">
        <ai-button
          variant="tertiary"
          :aria-label="$t('ux.molecules.modal.close.label')"
          @click="onBurgerMenuClose(slotProps.onClose)">
          <ai-icon name="close" :size="24" color="gold-900" />
        </ai-button>

        <ai-link route-name="index" @click="open = false">
          <ai-logo
            brand="AAI"
            :size="{ height: 32, width: 110 }"
            class="AiHeaderBurgerMenu-logo" />
        </ai-link>

        <ai-button
          variant="tertiary"
          :aria-label="$t('header.myAccount')"
          @click="page = 'account'">
          <ai-icon name="account" :size="24" color="gold-900" />
        </ai-button>
      </div>
    </template>

    <!-- Body -->
    <ai-transition name="slide-right">
      <!-- Default -->
      <div v-if="!page" class="AiHeaderBurgerMenu-body">
        <div class="AiHeaderBurgerMenu-defaultContent">
          {{
            '' /* Empty for now, see modifications from tickets ACCORDAI-1100 and ACCORDAI-1790 */
          }}
        </div>
        <div class="AiHeaderBurgerMenu-otherLinks">
          <ai-header-burger-footer-link
            :title="locale"
            :subtitle="$t('header.mobile.language')"
            @click="page = 'translations'" />
          <ai-header-burger-footer-link
            :title="bookingStore.currentCurrency"
            :subtitle="$t('header.mobile.currency')"
            @click="page = 'currencies'" />
        </div>
      </div>

      <!-- Translations -->
      <div
        v-if="page === 'translations'"
        class="AiHeaderBurgerMenu-body AiHeaderBurgerMenu-body--gold">
        <div class="AiHeaderBurgerMenu-main">
          <ai-button
            variant="tertiary"
            icon-left="arrow-left"
            :label="$t('header.mobile.backToMenu')"
            class="AiHeaderBurgerMenu-backToMenu"
            @click="page = ''" />

          <ai-typo
            as="div"
            variant="button-regular"
            class="AiHeaderBurgerMenu-pageTitle">
            {{ $t('header.mobile.language') }}
          </ai-typo>

          <ul>
            <li
              v-for="availableLocale of availableLocales"
              :key="availableLocale.code">
              <ai-link
                :url="switchLocalePath(availableLocale.code)"
                typo-variant="overline-01"
                :lang="availableLocale.code"
                :hreflang="availableLocale.code"
                class="AiHeaderBurgerMenu-localeLink"
                color="gold-900"
                undecorated
                @click="open = false">
                {{ availableLocale.name }}
              </ai-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- Currencies -->
      <div
        v-if="page === 'currencies'"
        class="AiHeaderBurgerMenu-body AiHeaderBurgerMenu-body--gold">
        <div class="AiHeaderBurgerMenu-main">
          <ai-button
            variant="tertiary"
            icon-left="arrow-left"
            :label="$t('header.mobile.backToMenu')"
            class="AiHeaderBurgerMenu-backToMenu"
            @click="page = ''" />

          <ai-typo
            as="div"
            variant="button-regular"
            class="AiHeaderBurgerMenu-pageTitle">
            {{ $t('header.mobile.currency') }}
          </ai-typo>

          <fieldset>
            <ai-radio
              v-for="suggestedCurrency in suggestedCurrencies"
              :key="suggestedCurrency.code"
              v-model="currentCurrency"
              class="AiHeaderBurgerMenu-currencyItem"
              input-name="suggested-currency"
              :value="suggestedCurrency.code"
              :label="`${
                suggestedCurrency.code
              } - ${suggestedCurrency.value.toLowerCase()}`" />
            <ai-separator
              class="AiHeaderBurgerMenu-currencySeparator"
              :inverse="inverse"
              margin="none" />
            <ai-radio
              v-for="availableCurrency in availableCurrencies"
              :key="availableCurrency.code"
              v-model="currentCurrency"
              class="AiHeaderBurgerMenu-currencyItem"
              input-name="currency"
              :value="availableCurrency.code"
              :label="`${
                availableCurrency.code
              } - ${availableCurrency.value.toLowerCase()}`" />
          </fieldset>
        </div>
      </div>

      <!-- Account -->
      <div
        v-if="page === 'account'"
        class="AiHeaderBurgerMenu-body AiHeaderBurgerMenu-body--gold">
        <div class="AiHeaderBurgerMenu-main">
          <ai-button
            variant="tertiary"
            icon-left="arrow-left"
            :label="$t('header.mobile.backToMenu')"
            class="AiHeaderBurgerMenu-backToMenu"
            @click="page = ''" />
          <account-submenu hide-close />
        </div>
      </div>
    </ai-transition>
  </ai-drawer>
</template>

<script setup lang="ts">
import { useLayoutStore } from '../../layout.store';

import AiHeaderBurgerFooterLink from './AiHeaderBurgerFooterLink.vue';

import { useBookingStore } from '~~/domains/booking';

type Props = {
  modelValue: boolean;
  inverse?: boolean;
  availableLocales: { code: string; name?: string }[];
  suggestedCurrencies: { code: string; value: string }[];
  availableCurrencies: { code: string; value: string }[];
};

type Emits = {
  (event: 'update:model-value', value: Props['modelValue']): void;
  (event: 'close'): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const bookingStore = useBookingStore();
const layoutStore = useLayoutStore();

const open = computed({
  get() {
    return props.modelValue;
  },
  set(value: Props['modelValue']) {
    emits('update:model-value', value);
  },
});

watch(open, v => {
  if (v && open.value) {
    layoutStore.setScrollLocked(true);
  } else {
    layoutStore.setScrollLocked(false);
  }
});

const page = ref<string>('');

const { locale } = useI18n();
const switchLocalePath = useSwitchLocalePath();

const currentCurrency = computed({
  get() {
    return bookingStore.currentCurrency;
  },
  set(value) {
    bookingStore.setCurrency(value);
  },
});

const onBurgerMenuClose = (onClose: () => boolean) => {
  onClose();
  emits('close');
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';

.AiHeaderBurgerMenu-header {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-inline: constants.$padding-02;
}

.AiHeaderBurgerMenu-logo {
  color: colors.$gold-900;
}

.AiHeaderBurgerMenu-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: calc(100dvh - 67px);

  &--gold {
    background: colors.$gold-100;
  }
}

.AiHeaderBurgerMenu-defaultContent {
  width: 100%;
}

.AiHeaderBurgerMenu-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: constants.$padding-01 constants.$padding-02;
}

.AiHeaderBurgerMenu-otherLinks {
  width: 100%;
}

.AiHeaderBurgerMenu-backToMenu {
  margin-bottom: constants.$margin-02;
}

.AiHeaderBurgerMenu-pageTitle {
  text-transform: uppercase;
  margin-bottom: constants.$margin-02;
}

/* Language page */
.AiHeaderBurgerMenu-localeLink {
  margin-bottom: constants.$margin-01;
}

/* Currency page */
.AiHeaderBurgerMenu-currencyItem {
  margin-bottom: constants.$margin-01;
  text-transform: capitalize;
}

.AiHeaderBurgerMenu-currencySeparator {
  margin: constants.$margin-01 0;
}
</style>
