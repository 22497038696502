import { default as _healthcheckqHFF6kBCjFMeta } from "/usr/src/app/packages/all-inclusive-app/pages/_healthcheck.vue?macro=true";
import { default as booking_45confirmation_45errorYB0JdhOOKfMeta } from "/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation-error.vue?macro=true";
import { default as booking_45confirmationOCA1o1TNWaMeta } from "/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation.vue?macro=true";
import { default as booking_45retryBuUCmPzNNqMeta } from "/usr/src/app/packages/all-inclusive-app/pages/booking-retry.vue?macro=true";
import { default as rate2uxmxXTHzkMeta } from "/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/rate.vue?macro=true";
import { default as room2JY6OMXl6FMeta } from "/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/room.vue?macro=true";
import { default as _91ridcode_934c03WsBBnfMeta } from "/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode].vue?macro=true";
import { default as details8pzARj1ZhaMeta } from "/usr/src/app/packages/all-inclusive-app/pages/booking/details.vue?macro=true";
import { default as paymentb2y0qclCPQMeta } from "/usr/src/app/packages/all-inclusive-app/pages/booking/payment.vue?macro=true";
import { default as bookingsN6uvVOyv2Meta } from "/usr/src/app/packages/all-inclusive-app/pages/booking.vue?macro=true";
import { default as continue_45booking3hrzmMu35mMeta } from "/usr/src/app/packages/all-inclusive-app/pages/continue-booking.vue?macro=true";
import { default as debugAuthddjNks9lsUMeta } from "/usr/src/app/packages/all-inclusive-app/pages/debugAuth.vue?macro=true";
import { default as indexY4QFcRAMwqMeta } from "/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/index.vue?macro=true";
import { default as _91offerSlug_93K9kdhYcjVqMeta } from "/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/offer/[offerSlug].vue?macro=true";
import { default as indexSYQeRCDx2TMeta } from "/usr/src/app/packages/all-inclusive-app/pages/index.vue?macro=true";
import { default as _91slug_93IAASyQZ68gMeta } from "/usr/src/app/packages/all-inclusive-app/pages/legal-page/[slug].vue?macro=true";
import { default as maintenance8PnTKBquPDMeta } from "/usr/src/app/packages/all-inclusive-app/pages/maintenance.vue?macro=true";
import { default as _91slug_935yhvTgs9KDMeta } from "/usr/src/app/packages/all-inclusive-app/pages/other-page/[slug].vue?macro=true";
import { default as searchkZZbnzUBeCMeta } from "/usr/src/app/packages/all-inclusive-app/pages/search.vue?macro=true";
export default [
  {
    name: _healthcheckqHFF6kBCjFMeta?.name ?? "_healthcheck___en",
    path: _healthcheckqHFF6kBCjFMeta?.path ?? "/en/_healthcheck/",
    meta: _healthcheckqHFF6kBCjFMeta || {},
    alias: _healthcheckqHFF6kBCjFMeta?.alias || [],
    redirect: _healthcheckqHFF6kBCjFMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: _healthcheckqHFF6kBCjFMeta?.name ?? "_healthcheck___ru",
    path: _healthcheckqHFF6kBCjFMeta?.path ?? "/ru/_healthcheck/",
    meta: _healthcheckqHFF6kBCjFMeta || {},
    alias: _healthcheckqHFF6kBCjFMeta?.alias || [],
    redirect: _healthcheckqHFF6kBCjFMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: _healthcheckqHFF6kBCjFMeta?.name ?? "_healthcheck___tr",
    path: _healthcheckqHFF6kBCjFMeta?.path ?? "/tr/_healthcheck/",
    meta: _healthcheckqHFF6kBCjFMeta || {},
    alias: _healthcheckqHFF6kBCjFMeta?.alias || [],
    redirect: _healthcheckqHFF6kBCjFMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: _healthcheckqHFF6kBCjFMeta?.name ?? "_healthcheck___de",
    path: _healthcheckqHFF6kBCjFMeta?.path ?? "/de/_healthcheck/",
    meta: _healthcheckqHFF6kBCjFMeta || {},
    alias: _healthcheckqHFF6kBCjFMeta?.alias || [],
    redirect: _healthcheckqHFF6kBCjFMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: _healthcheckqHFF6kBCjFMeta?.name ?? "_healthcheck___fr",
    path: _healthcheckqHFF6kBCjFMeta?.path ?? "/fr/_healthcheck/",
    meta: _healthcheckqHFF6kBCjFMeta || {},
    alias: _healthcheckqHFF6kBCjFMeta?.alias || [],
    redirect: _healthcheckqHFF6kBCjFMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: _healthcheckqHFF6kBCjFMeta?.name ?? "_healthcheck___es",
    path: _healthcheckqHFF6kBCjFMeta?.path ?? "/es/_healthcheck/",
    meta: _healthcheckqHFF6kBCjFMeta || {},
    alias: _healthcheckqHFF6kBCjFMeta?.alias || [],
    redirect: _healthcheckqHFF6kBCjFMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: _healthcheckqHFF6kBCjFMeta?.name ?? "_healthcheck___xx",
    path: _healthcheckqHFF6kBCjFMeta?.path ?? "/xx/_healthcheck/",
    meta: _healthcheckqHFF6kBCjFMeta || {},
    alias: _healthcheckqHFF6kBCjFMeta?.alias || [],
    redirect: _healthcheckqHFF6kBCjFMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmation_45errorYB0JdhOOKfMeta?.name ?? "booking-confirmation-error___en",
    path: booking_45confirmation_45errorYB0JdhOOKfMeta?.path ?? "/en/booking-confirmation-error/",
    meta: booking_45confirmation_45errorYB0JdhOOKfMeta || {},
    alias: booking_45confirmation_45errorYB0JdhOOKfMeta?.alias || [],
    redirect: booking_45confirmation_45errorYB0JdhOOKfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation-error.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmation_45errorYB0JdhOOKfMeta?.name ?? "booking-confirmation-error___ru",
    path: booking_45confirmation_45errorYB0JdhOOKfMeta?.path ?? "/ru/booking-confirmation-error/",
    meta: booking_45confirmation_45errorYB0JdhOOKfMeta || {},
    alias: booking_45confirmation_45errorYB0JdhOOKfMeta?.alias || [],
    redirect: booking_45confirmation_45errorYB0JdhOOKfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation-error.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmation_45errorYB0JdhOOKfMeta?.name ?? "booking-confirmation-error___tr",
    path: booking_45confirmation_45errorYB0JdhOOKfMeta?.path ?? "/tr/booking-confirmation-error/",
    meta: booking_45confirmation_45errorYB0JdhOOKfMeta || {},
    alias: booking_45confirmation_45errorYB0JdhOOKfMeta?.alias || [],
    redirect: booking_45confirmation_45errorYB0JdhOOKfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation-error.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmation_45errorYB0JdhOOKfMeta?.name ?? "booking-confirmation-error___de",
    path: booking_45confirmation_45errorYB0JdhOOKfMeta?.path ?? "/de/booking-confirmation-error/",
    meta: booking_45confirmation_45errorYB0JdhOOKfMeta || {},
    alias: booking_45confirmation_45errorYB0JdhOOKfMeta?.alias || [],
    redirect: booking_45confirmation_45errorYB0JdhOOKfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation-error.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmation_45errorYB0JdhOOKfMeta?.name ?? "booking-confirmation-error___fr",
    path: booking_45confirmation_45errorYB0JdhOOKfMeta?.path ?? "/fr/booking-confirmation-error/",
    meta: booking_45confirmation_45errorYB0JdhOOKfMeta || {},
    alias: booking_45confirmation_45errorYB0JdhOOKfMeta?.alias || [],
    redirect: booking_45confirmation_45errorYB0JdhOOKfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation-error.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmation_45errorYB0JdhOOKfMeta?.name ?? "booking-confirmation-error___es",
    path: booking_45confirmation_45errorYB0JdhOOKfMeta?.path ?? "/es/booking-confirmation-error/",
    meta: booking_45confirmation_45errorYB0JdhOOKfMeta || {},
    alias: booking_45confirmation_45errorYB0JdhOOKfMeta?.alias || [],
    redirect: booking_45confirmation_45errorYB0JdhOOKfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation-error.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmation_45errorYB0JdhOOKfMeta?.name ?? "booking-confirmation-error___xx",
    path: booking_45confirmation_45errorYB0JdhOOKfMeta?.path ?? "/xx/booking-confirmation-error/",
    meta: booking_45confirmation_45errorYB0JdhOOKfMeta || {},
    alias: booking_45confirmation_45errorYB0JdhOOKfMeta?.alias || [],
    redirect: booking_45confirmation_45errorYB0JdhOOKfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation-error.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmationOCA1o1TNWaMeta?.name ?? "booking-confirmation___en",
    path: booking_45confirmationOCA1o1TNWaMeta?.path ?? "/en/confirmation-page/",
    meta: booking_45confirmationOCA1o1TNWaMeta || {},
    alias: booking_45confirmationOCA1o1TNWaMeta?.alias || [],
    redirect: booking_45confirmationOCA1o1TNWaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmationOCA1o1TNWaMeta?.name ?? "booking-confirmation___ru",
    path: booking_45confirmationOCA1o1TNWaMeta?.path ?? "/ru/c%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0-%D0%BF%D0%BE%D0%B4%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F/",
    meta: booking_45confirmationOCA1o1TNWaMeta || {},
    alias: booking_45confirmationOCA1o1TNWaMeta?.alias || [],
    redirect: booking_45confirmationOCA1o1TNWaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmationOCA1o1TNWaMeta?.name ?? "booking-confirmation___tr",
    path: booking_45confirmationOCA1o1TNWaMeta?.path ?? "/tr/onay-sayfas%C4%B1/",
    meta: booking_45confirmationOCA1o1TNWaMeta || {},
    alias: booking_45confirmationOCA1o1TNWaMeta?.alias || [],
    redirect: booking_45confirmationOCA1o1TNWaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmationOCA1o1TNWaMeta?.name ?? "booking-confirmation___de",
    path: booking_45confirmationOCA1o1TNWaMeta?.path ?? "/de/best%C3%A4tigungsseite/",
    meta: booking_45confirmationOCA1o1TNWaMeta || {},
    alias: booking_45confirmationOCA1o1TNWaMeta?.alias || [],
    redirect: booking_45confirmationOCA1o1TNWaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmationOCA1o1TNWaMeta?.name ?? "booking-confirmation___fr",
    path: booking_45confirmationOCA1o1TNWaMeta?.path ?? "/fr/page-de-confirmation/",
    meta: booking_45confirmationOCA1o1TNWaMeta || {},
    alias: booking_45confirmationOCA1o1TNWaMeta?.alias || [],
    redirect: booking_45confirmationOCA1o1TNWaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmationOCA1o1TNWaMeta?.name ?? "booking-confirmation___es",
    path: booking_45confirmationOCA1o1TNWaMeta?.path ?? "/es/booking-confirmation/",
    meta: booking_45confirmationOCA1o1TNWaMeta || {},
    alias: booking_45confirmationOCA1o1TNWaMeta?.alias || [],
    redirect: booking_45confirmationOCA1o1TNWaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation.vue").then(m => m.default || m)
  },
  {
    name: booking_45confirmationOCA1o1TNWaMeta?.name ?? "booking-confirmation___xx",
    path: booking_45confirmationOCA1o1TNWaMeta?.path ?? "/xx/booking-confirmation/",
    meta: booking_45confirmationOCA1o1TNWaMeta || {},
    alias: booking_45confirmationOCA1o1TNWaMeta?.alias || [],
    redirect: booking_45confirmationOCA1o1TNWaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-confirmation.vue").then(m => m.default || m)
  },
  {
    name: booking_45retryBuUCmPzNNqMeta?.name ?? "booking-retry___en",
    path: booking_45retryBuUCmPzNNqMeta?.path ?? "/en/booking-retry/",
    meta: booking_45retryBuUCmPzNNqMeta || {},
    alias: booking_45retryBuUCmPzNNqMeta?.alias || [],
    redirect: booking_45retryBuUCmPzNNqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-retry.vue").then(m => m.default || m)
  },
  {
    name: booking_45retryBuUCmPzNNqMeta?.name ?? "booking-retry___ru",
    path: booking_45retryBuUCmPzNNqMeta?.path ?? "/ru/booking-retry/",
    meta: booking_45retryBuUCmPzNNqMeta || {},
    alias: booking_45retryBuUCmPzNNqMeta?.alias || [],
    redirect: booking_45retryBuUCmPzNNqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-retry.vue").then(m => m.default || m)
  },
  {
    name: booking_45retryBuUCmPzNNqMeta?.name ?? "booking-retry___tr",
    path: booking_45retryBuUCmPzNNqMeta?.path ?? "/tr/booking-retry/",
    meta: booking_45retryBuUCmPzNNqMeta || {},
    alias: booking_45retryBuUCmPzNNqMeta?.alias || [],
    redirect: booking_45retryBuUCmPzNNqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-retry.vue").then(m => m.default || m)
  },
  {
    name: booking_45retryBuUCmPzNNqMeta?.name ?? "booking-retry___de",
    path: booking_45retryBuUCmPzNNqMeta?.path ?? "/de/booking-retry/",
    meta: booking_45retryBuUCmPzNNqMeta || {},
    alias: booking_45retryBuUCmPzNNqMeta?.alias || [],
    redirect: booking_45retryBuUCmPzNNqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-retry.vue").then(m => m.default || m)
  },
  {
    name: booking_45retryBuUCmPzNNqMeta?.name ?? "booking-retry___fr",
    path: booking_45retryBuUCmPzNNqMeta?.path ?? "/fr/booking-retry/",
    meta: booking_45retryBuUCmPzNNqMeta || {},
    alias: booking_45retryBuUCmPzNNqMeta?.alias || [],
    redirect: booking_45retryBuUCmPzNNqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-retry.vue").then(m => m.default || m)
  },
  {
    name: booking_45retryBuUCmPzNNqMeta?.name ?? "booking-retry___es",
    path: booking_45retryBuUCmPzNNqMeta?.path ?? "/es/booking-retry/",
    meta: booking_45retryBuUCmPzNNqMeta || {},
    alias: booking_45retryBuUCmPzNNqMeta?.alias || [],
    redirect: booking_45retryBuUCmPzNNqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-retry.vue").then(m => m.default || m)
  },
  {
    name: booking_45retryBuUCmPzNNqMeta?.name ?? "booking-retry___xx",
    path: booking_45retryBuUCmPzNNqMeta?.path ?? "/xx/booking-retry/",
    meta: booking_45retryBuUCmPzNNqMeta || {},
    alias: booking_45retryBuUCmPzNNqMeta?.alias || [],
    redirect: booking_45retryBuUCmPzNNqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking-retry.vue").then(m => m.default || m)
  },
  {
    name: bookingsN6uvVOyv2Meta?.name ?? "booking___en",
    path: bookingsN6uvVOyv2Meta?.path ?? "/en/booking/",
    meta: bookingsN6uvVOyv2Meta || {},
    alias: bookingsN6uvVOyv2Meta?.alias || [],
    redirect: bookingsN6uvVOyv2Meta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking.vue").then(m => m.default || m),
    children: [
  {
    name: _91ridcode_934c03WsBBnfMeta?.name ?? "booking-ridcode___en",
    path: _91ridcode_934c03WsBBnfMeta?.path ?? ":ridcode()/",
    meta: _91ridcode_934c03WsBBnfMeta || {},
    alias: _91ridcode_934c03WsBBnfMeta?.alias || [],
    redirect: _91ridcode_934c03WsBBnfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode].vue").then(m => m.default || m),
    children: [
  {
    name: rate2uxmxXTHzkMeta?.name ?? "booking-ridcode-rate___en",
    path: rate2uxmxXTHzkMeta?.path ?? "/en/booking/:ridcode()/rate/",
    meta: rate2uxmxXTHzkMeta || {},
    alias: rate2uxmxXTHzkMeta?.alias || [],
    redirect: rate2uxmxXTHzkMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/rate.vue").then(m => m.default || m)
  },
  {
    name: room2JY6OMXl6FMeta?.name ?? "booking-ridcode-room___en",
    path: room2JY6OMXl6FMeta?.path ?? "/en/booking/:ridcode()/room/",
    meta: room2JY6OMXl6FMeta || {},
    alias: room2JY6OMXl6FMeta?.alias || [],
    redirect: room2JY6OMXl6FMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/room.vue").then(m => m.default || m)
  }
]
  },
  {
    name: details8pzARj1ZhaMeta?.name ?? "booking-details___en",
    path: details8pzARj1ZhaMeta?.path ?? "/en/booking/details/",
    meta: details8pzARj1ZhaMeta || {},
    alias: details8pzARj1ZhaMeta?.alias || [],
    redirect: details8pzARj1ZhaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/details.vue").then(m => m.default || m)
  },
  {
    name: paymentb2y0qclCPQMeta?.name ?? "booking-payment___en",
    path: paymentb2y0qclCPQMeta?.path ?? "/en/booking/payment/",
    meta: paymentb2y0qclCPQMeta || {},
    alias: paymentb2y0qclCPQMeta?.alias || [],
    redirect: paymentb2y0qclCPQMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: bookingsN6uvVOyv2Meta?.name ?? "booking___ru",
    path: bookingsN6uvVOyv2Meta?.path ?? "/ru/%D0%91%D1%80%D0%BE%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5/",
    meta: bookingsN6uvVOyv2Meta || {},
    alias: bookingsN6uvVOyv2Meta?.alias || [],
    redirect: bookingsN6uvVOyv2Meta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking.vue").then(m => m.default || m),
    children: [
  {
    name: _91ridcode_934c03WsBBnfMeta?.name ?? "booking-ridcode___ru",
    path: _91ridcode_934c03WsBBnfMeta?.path ?? ":ridcode()/",
    meta: _91ridcode_934c03WsBBnfMeta || {},
    alias: _91ridcode_934c03WsBBnfMeta?.alias || [],
    redirect: _91ridcode_934c03WsBBnfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode].vue").then(m => m.default || m),
    children: [
  {
    name: rate2uxmxXTHzkMeta?.name ?? "booking-ridcode-rate___ru",
    path: rate2uxmxXTHzkMeta?.path ?? "/ru/%D0%91%D1%80%D0%BE%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5/:ridcode()/%D0%BF%D1%80%D0%B5%D0%B4%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5/",
    meta: rate2uxmxXTHzkMeta || {},
    alias: rate2uxmxXTHzkMeta?.alias || [],
    redirect: rate2uxmxXTHzkMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/rate.vue").then(m => m.default || m)
  },
  {
    name: room2JY6OMXl6FMeta?.name ?? "booking-ridcode-room___ru",
    path: room2JY6OMXl6FMeta?.path ?? "/ru/%D0%91%D1%80%D0%BE%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5/:ridcode()/c%D0%BF%D0%B0%D0%BB%D1%8C%D0%BD%D1%8F/",
    meta: room2JY6OMXl6FMeta || {},
    alias: room2JY6OMXl6FMeta?.alias || [],
    redirect: room2JY6OMXl6FMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/room.vue").then(m => m.default || m)
  }
]
  },
  {
    name: details8pzARj1ZhaMeta?.name ?? "booking-details___ru",
    path: details8pzARj1ZhaMeta?.path ?? "/ru/%D0%91%D1%80%D0%BE%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5/%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F/",
    meta: details8pzARj1ZhaMeta || {},
    alias: details8pzARj1ZhaMeta?.alias || [],
    redirect: details8pzARj1ZhaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/details.vue").then(m => m.default || m)
  },
  {
    name: paymentb2y0qclCPQMeta?.name ?? "booking-payment___ru",
    path: paymentb2y0qclCPQMeta?.path ?? "/ru/%D0%91%D1%80%D0%BE%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5/%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B0/",
    meta: paymentb2y0qclCPQMeta || {},
    alias: paymentb2y0qclCPQMeta?.alias || [],
    redirect: paymentb2y0qclCPQMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: bookingsN6uvVOyv2Meta?.name ?? "booking___tr",
    path: bookingsN6uvVOyv2Meta?.path ?? "/tr/rezervasyon/",
    meta: bookingsN6uvVOyv2Meta || {},
    alias: bookingsN6uvVOyv2Meta?.alias || [],
    redirect: bookingsN6uvVOyv2Meta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking.vue").then(m => m.default || m),
    children: [
  {
    name: _91ridcode_934c03WsBBnfMeta?.name ?? "booking-ridcode___tr",
    path: _91ridcode_934c03WsBBnfMeta?.path ?? ":ridcode()/",
    meta: _91ridcode_934c03WsBBnfMeta || {},
    alias: _91ridcode_934c03WsBBnfMeta?.alias || [],
    redirect: _91ridcode_934c03WsBBnfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode].vue").then(m => m.default || m),
    children: [
  {
    name: rate2uxmxXTHzkMeta?.name ?? "booking-ridcode-rate___tr",
    path: rate2uxmxXTHzkMeta?.path ?? "/tr/rezervasyon/:ridcode()/teklif/",
    meta: rate2uxmxXTHzkMeta || {},
    alias: rate2uxmxXTHzkMeta?.alias || [],
    redirect: rate2uxmxXTHzkMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/rate.vue").then(m => m.default || m)
  },
  {
    name: room2JY6OMXl6FMeta?.name ?? "booking-ridcode-room___tr",
    path: room2JY6OMXl6FMeta?.path ?? "/tr/rezervasyon/:ridcode()/yatak-odasi/",
    meta: room2JY6OMXl6FMeta || {},
    alias: room2JY6OMXl6FMeta?.alias || [],
    redirect: room2JY6OMXl6FMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/room.vue").then(m => m.default || m)
  }
]
  },
  {
    name: details8pzARj1ZhaMeta?.name ?? "booking-details___tr",
    path: details8pzARj1ZhaMeta?.path ?? "/tr/rezervasyon/bilgi/",
    meta: details8pzARj1ZhaMeta || {},
    alias: details8pzARj1ZhaMeta?.alias || [],
    redirect: details8pzARj1ZhaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/details.vue").then(m => m.default || m)
  },
  {
    name: paymentb2y0qclCPQMeta?.name ?? "booking-payment___tr",
    path: paymentb2y0qclCPQMeta?.path ?? "/tr/rezervasyon/odeme/",
    meta: paymentb2y0qclCPQMeta || {},
    alias: paymentb2y0qclCPQMeta?.alias || [],
    redirect: paymentb2y0qclCPQMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: bookingsN6uvVOyv2Meta?.name ?? "booking___de",
    path: bookingsN6uvVOyv2Meta?.path ?? "/de/buchung/",
    meta: bookingsN6uvVOyv2Meta || {},
    alias: bookingsN6uvVOyv2Meta?.alias || [],
    redirect: bookingsN6uvVOyv2Meta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking.vue").then(m => m.default || m),
    children: [
  {
    name: _91ridcode_934c03WsBBnfMeta?.name ?? "booking-ridcode___de",
    path: _91ridcode_934c03WsBBnfMeta?.path ?? ":ridcode()/",
    meta: _91ridcode_934c03WsBBnfMeta || {},
    alias: _91ridcode_934c03WsBBnfMeta?.alias || [],
    redirect: _91ridcode_934c03WsBBnfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode].vue").then(m => m.default || m),
    children: [
  {
    name: rate2uxmxXTHzkMeta?.name ?? "booking-ridcode-rate___de",
    path: rate2uxmxXTHzkMeta?.path ?? "/de/buchung/:ridcode()/angebot/",
    meta: rate2uxmxXTHzkMeta || {},
    alias: rate2uxmxXTHzkMeta?.alias || [],
    redirect: rate2uxmxXTHzkMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/rate.vue").then(m => m.default || m)
  },
  {
    name: room2JY6OMXl6FMeta?.name ?? "booking-ridcode-room___de",
    path: room2JY6OMXl6FMeta?.path ?? "/de/buchung/:ridcode()/schlafzimmer/",
    meta: room2JY6OMXl6FMeta || {},
    alias: room2JY6OMXl6FMeta?.alias || [],
    redirect: room2JY6OMXl6FMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/room.vue").then(m => m.default || m)
  }
]
  },
  {
    name: details8pzARj1ZhaMeta?.name ?? "booking-details___de",
    path: details8pzARj1ZhaMeta?.path ?? "/de/buchung/informationen/",
    meta: details8pzARj1ZhaMeta || {},
    alias: details8pzARj1ZhaMeta?.alias || [],
    redirect: details8pzARj1ZhaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/details.vue").then(m => m.default || m)
  },
  {
    name: paymentb2y0qclCPQMeta?.name ?? "booking-payment___de",
    path: paymentb2y0qclCPQMeta?.path ?? "/de/buchung/zahlung/",
    meta: paymentb2y0qclCPQMeta || {},
    alias: paymentb2y0qclCPQMeta?.alias || [],
    redirect: paymentb2y0qclCPQMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: bookingsN6uvVOyv2Meta?.name ?? "booking___fr",
    path: bookingsN6uvVOyv2Meta?.path ?? "/fr/reservation/",
    meta: bookingsN6uvVOyv2Meta || {},
    alias: bookingsN6uvVOyv2Meta?.alias || [],
    redirect: bookingsN6uvVOyv2Meta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking.vue").then(m => m.default || m),
    children: [
  {
    name: _91ridcode_934c03WsBBnfMeta?.name ?? "booking-ridcode___fr",
    path: _91ridcode_934c03WsBBnfMeta?.path ?? ":ridcode()/",
    meta: _91ridcode_934c03WsBBnfMeta || {},
    alias: _91ridcode_934c03WsBBnfMeta?.alias || [],
    redirect: _91ridcode_934c03WsBBnfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode].vue").then(m => m.default || m),
    children: [
  {
    name: rate2uxmxXTHzkMeta?.name ?? "booking-ridcode-rate___fr",
    path: rate2uxmxXTHzkMeta?.path ?? "/fr/reservation/:ridcode()/offre/",
    meta: rate2uxmxXTHzkMeta || {},
    alias: rate2uxmxXTHzkMeta?.alias || [],
    redirect: rate2uxmxXTHzkMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/rate.vue").then(m => m.default || m)
  },
  {
    name: room2JY6OMXl6FMeta?.name ?? "booking-ridcode-room___fr",
    path: room2JY6OMXl6FMeta?.path ?? "/fr/reservation/:ridcode()/chambre/",
    meta: room2JY6OMXl6FMeta || {},
    alias: room2JY6OMXl6FMeta?.alias || [],
    redirect: room2JY6OMXl6FMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/room.vue").then(m => m.default || m)
  }
]
  },
  {
    name: details8pzARj1ZhaMeta?.name ?? "booking-details___fr",
    path: details8pzARj1ZhaMeta?.path ?? "/fr/reservation/informations/",
    meta: details8pzARj1ZhaMeta || {},
    alias: details8pzARj1ZhaMeta?.alias || [],
    redirect: details8pzARj1ZhaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/details.vue").then(m => m.default || m)
  },
  {
    name: paymentb2y0qclCPQMeta?.name ?? "booking-payment___fr",
    path: paymentb2y0qclCPQMeta?.path ?? "/fr/reservation/mode-de-paiement/",
    meta: paymentb2y0qclCPQMeta || {},
    alias: paymentb2y0qclCPQMeta?.alias || [],
    redirect: paymentb2y0qclCPQMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: bookingsN6uvVOyv2Meta?.name ?? "booking___es",
    path: bookingsN6uvVOyv2Meta?.path ?? "/es/booking/",
    meta: bookingsN6uvVOyv2Meta || {},
    alias: bookingsN6uvVOyv2Meta?.alias || [],
    redirect: bookingsN6uvVOyv2Meta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking.vue").then(m => m.default || m),
    children: [
  {
    name: _91ridcode_934c03WsBBnfMeta?.name ?? "booking-ridcode___es",
    path: _91ridcode_934c03WsBBnfMeta?.path ?? ":ridcode()/",
    meta: _91ridcode_934c03WsBBnfMeta || {},
    alias: _91ridcode_934c03WsBBnfMeta?.alias || [],
    redirect: _91ridcode_934c03WsBBnfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode].vue").then(m => m.default || m),
    children: [
  {
    name: rate2uxmxXTHzkMeta?.name ?? "booking-ridcode-rate___es",
    path: rate2uxmxXTHzkMeta?.path ?? "rate/",
    meta: rate2uxmxXTHzkMeta || {},
    alias: rate2uxmxXTHzkMeta?.alias || [],
    redirect: rate2uxmxXTHzkMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/rate.vue").then(m => m.default || m)
  },
  {
    name: room2JY6OMXl6FMeta?.name ?? "booking-ridcode-room___es",
    path: room2JY6OMXl6FMeta?.path ?? "room/",
    meta: room2JY6OMXl6FMeta || {},
    alias: room2JY6OMXl6FMeta?.alias || [],
    redirect: room2JY6OMXl6FMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/room.vue").then(m => m.default || m)
  }
]
  },
  {
    name: details8pzARj1ZhaMeta?.name ?? "booking-details___es",
    path: details8pzARj1ZhaMeta?.path ?? "details/",
    meta: details8pzARj1ZhaMeta || {},
    alias: details8pzARj1ZhaMeta?.alias || [],
    redirect: details8pzARj1ZhaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/details.vue").then(m => m.default || m)
  },
  {
    name: paymentb2y0qclCPQMeta?.name ?? "booking-payment___es",
    path: paymentb2y0qclCPQMeta?.path ?? "payment/",
    meta: paymentb2y0qclCPQMeta || {},
    alias: paymentb2y0qclCPQMeta?.alias || [],
    redirect: paymentb2y0qclCPQMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: bookingsN6uvVOyv2Meta?.name ?? "booking___xx",
    path: bookingsN6uvVOyv2Meta?.path ?? "/xx/booking/",
    meta: bookingsN6uvVOyv2Meta || {},
    alias: bookingsN6uvVOyv2Meta?.alias || [],
    redirect: bookingsN6uvVOyv2Meta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking.vue").then(m => m.default || m),
    children: [
  {
    name: _91ridcode_934c03WsBBnfMeta?.name ?? "booking-ridcode___xx",
    path: _91ridcode_934c03WsBBnfMeta?.path ?? ":ridcode()/",
    meta: _91ridcode_934c03WsBBnfMeta || {},
    alias: _91ridcode_934c03WsBBnfMeta?.alias || [],
    redirect: _91ridcode_934c03WsBBnfMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode].vue").then(m => m.default || m),
    children: [
  {
    name: rate2uxmxXTHzkMeta?.name ?? "booking-ridcode-rate___xx",
    path: rate2uxmxXTHzkMeta?.path ?? "rate/",
    meta: rate2uxmxXTHzkMeta || {},
    alias: rate2uxmxXTHzkMeta?.alias || [],
    redirect: rate2uxmxXTHzkMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/rate.vue").then(m => m.default || m)
  },
  {
    name: room2JY6OMXl6FMeta?.name ?? "booking-ridcode-room___xx",
    path: room2JY6OMXl6FMeta?.path ?? "room/",
    meta: room2JY6OMXl6FMeta || {},
    alias: room2JY6OMXl6FMeta?.alias || [],
    redirect: room2JY6OMXl6FMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/[ridcode]/room.vue").then(m => m.default || m)
  }
]
  },
  {
    name: details8pzARj1ZhaMeta?.name ?? "booking-details___xx",
    path: details8pzARj1ZhaMeta?.path ?? "details/",
    meta: details8pzARj1ZhaMeta || {},
    alias: details8pzARj1ZhaMeta?.alias || [],
    redirect: details8pzARj1ZhaMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/details.vue").then(m => m.default || m)
  },
  {
    name: paymentb2y0qclCPQMeta?.name ?? "booking-payment___xx",
    path: paymentb2y0qclCPQMeta?.path ?? "payment/",
    meta: paymentb2y0qclCPQMeta || {},
    alias: paymentb2y0qclCPQMeta?.alias || [],
    redirect: paymentb2y0qclCPQMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/booking/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: continue_45booking3hrzmMu35mMeta?.name ?? "continue-booking___en",
    path: continue_45booking3hrzmMu35mMeta?.path ?? "/en/continue-booking/",
    meta: continue_45booking3hrzmMu35mMeta || {},
    alias: continue_45booking3hrzmMu35mMeta?.alias || [],
    redirect: continue_45booking3hrzmMu35mMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/continue-booking.vue").then(m => m.default || m)
  },
  {
    name: continue_45booking3hrzmMu35mMeta?.name ?? "continue-booking___ru",
    path: continue_45booking3hrzmMu35mMeta?.path ?? "/ru/continue-booking/",
    meta: continue_45booking3hrzmMu35mMeta || {},
    alias: continue_45booking3hrzmMu35mMeta?.alias || [],
    redirect: continue_45booking3hrzmMu35mMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/continue-booking.vue").then(m => m.default || m)
  },
  {
    name: continue_45booking3hrzmMu35mMeta?.name ?? "continue-booking___tr",
    path: continue_45booking3hrzmMu35mMeta?.path ?? "/tr/continue-booking/",
    meta: continue_45booking3hrzmMu35mMeta || {},
    alias: continue_45booking3hrzmMu35mMeta?.alias || [],
    redirect: continue_45booking3hrzmMu35mMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/continue-booking.vue").then(m => m.default || m)
  },
  {
    name: continue_45booking3hrzmMu35mMeta?.name ?? "continue-booking___de",
    path: continue_45booking3hrzmMu35mMeta?.path ?? "/de/continue-booking/",
    meta: continue_45booking3hrzmMu35mMeta || {},
    alias: continue_45booking3hrzmMu35mMeta?.alias || [],
    redirect: continue_45booking3hrzmMu35mMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/continue-booking.vue").then(m => m.default || m)
  },
  {
    name: continue_45booking3hrzmMu35mMeta?.name ?? "continue-booking___fr",
    path: continue_45booking3hrzmMu35mMeta?.path ?? "/fr/continue-booking/",
    meta: continue_45booking3hrzmMu35mMeta || {},
    alias: continue_45booking3hrzmMu35mMeta?.alias || [],
    redirect: continue_45booking3hrzmMu35mMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/continue-booking.vue").then(m => m.default || m)
  },
  {
    name: continue_45booking3hrzmMu35mMeta?.name ?? "continue-booking___es",
    path: continue_45booking3hrzmMu35mMeta?.path ?? "/es/continue-booking/",
    meta: continue_45booking3hrzmMu35mMeta || {},
    alias: continue_45booking3hrzmMu35mMeta?.alias || [],
    redirect: continue_45booking3hrzmMu35mMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/continue-booking.vue").then(m => m.default || m)
  },
  {
    name: continue_45booking3hrzmMu35mMeta?.name ?? "continue-booking___xx",
    path: continue_45booking3hrzmMu35mMeta?.path ?? "/xx/continue-booking/",
    meta: continue_45booking3hrzmMu35mMeta || {},
    alias: continue_45booking3hrzmMu35mMeta?.alias || [],
    redirect: continue_45booking3hrzmMu35mMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/continue-booking.vue").then(m => m.default || m)
  },
  {
    name: debugAuthddjNks9lsUMeta?.name ?? "debugAuth___en",
    path: debugAuthddjNks9lsUMeta?.path ?? "/en/debugAuth/",
    meta: debugAuthddjNks9lsUMeta || {},
    alias: debugAuthddjNks9lsUMeta?.alias || [],
    redirect: debugAuthddjNks9lsUMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/debugAuth.vue").then(m => m.default || m)
  },
  {
    name: debugAuthddjNks9lsUMeta?.name ?? "debugAuth___ru",
    path: debugAuthddjNks9lsUMeta?.path ?? "/ru/debugAuth/",
    meta: debugAuthddjNks9lsUMeta || {},
    alias: debugAuthddjNks9lsUMeta?.alias || [],
    redirect: debugAuthddjNks9lsUMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/debugAuth.vue").then(m => m.default || m)
  },
  {
    name: debugAuthddjNks9lsUMeta?.name ?? "debugAuth___tr",
    path: debugAuthddjNks9lsUMeta?.path ?? "/tr/debugAuth/",
    meta: debugAuthddjNks9lsUMeta || {},
    alias: debugAuthddjNks9lsUMeta?.alias || [],
    redirect: debugAuthddjNks9lsUMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/debugAuth.vue").then(m => m.default || m)
  },
  {
    name: debugAuthddjNks9lsUMeta?.name ?? "debugAuth___de",
    path: debugAuthddjNks9lsUMeta?.path ?? "/de/debugAuth/",
    meta: debugAuthddjNks9lsUMeta || {},
    alias: debugAuthddjNks9lsUMeta?.alias || [],
    redirect: debugAuthddjNks9lsUMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/debugAuth.vue").then(m => m.default || m)
  },
  {
    name: debugAuthddjNks9lsUMeta?.name ?? "debugAuth___fr",
    path: debugAuthddjNks9lsUMeta?.path ?? "/fr/debugAuth/",
    meta: debugAuthddjNks9lsUMeta || {},
    alias: debugAuthddjNks9lsUMeta?.alias || [],
    redirect: debugAuthddjNks9lsUMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/debugAuth.vue").then(m => m.default || m)
  },
  {
    name: debugAuthddjNks9lsUMeta?.name ?? "debugAuth___es",
    path: debugAuthddjNks9lsUMeta?.path ?? "/es/debugAuth/",
    meta: debugAuthddjNks9lsUMeta || {},
    alias: debugAuthddjNks9lsUMeta?.alias || [],
    redirect: debugAuthddjNks9lsUMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/debugAuth.vue").then(m => m.default || m)
  },
  {
    name: debugAuthddjNks9lsUMeta?.name ?? "debugAuth___xx",
    path: debugAuthddjNks9lsUMeta?.path ?? "/xx/debugAuth/",
    meta: debugAuthddjNks9lsUMeta || {},
    alias: debugAuthddjNks9lsUMeta?.alias || [],
    redirect: debugAuthddjNks9lsUMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/debugAuth.vue").then(m => m.default || m)
  },
  {
    name: indexY4QFcRAMwqMeta?.name ?? "hotel-hotelSlug___en",
    path: indexY4QFcRAMwqMeta?.path ?? "/en/hotel/:hotelSlug()/",
    meta: indexY4QFcRAMwqMeta || {},
    alias: indexY4QFcRAMwqMeta?.alias || [],
    redirect: indexY4QFcRAMwqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4QFcRAMwqMeta?.name ?? "hotel-hotelSlug___ru",
    path: indexY4QFcRAMwqMeta?.path ?? "/ru/hotel/:hotelSlug()/",
    meta: indexY4QFcRAMwqMeta || {},
    alias: indexY4QFcRAMwqMeta?.alias || [],
    redirect: indexY4QFcRAMwqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4QFcRAMwqMeta?.name ?? "hotel-hotelSlug___tr",
    path: indexY4QFcRAMwqMeta?.path ?? "/tr/hotel/:hotelSlug()/",
    meta: indexY4QFcRAMwqMeta || {},
    alias: indexY4QFcRAMwqMeta?.alias || [],
    redirect: indexY4QFcRAMwqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4QFcRAMwqMeta?.name ?? "hotel-hotelSlug___de",
    path: indexY4QFcRAMwqMeta?.path ?? "/de/hotel/:hotelSlug()/",
    meta: indexY4QFcRAMwqMeta || {},
    alias: indexY4QFcRAMwqMeta?.alias || [],
    redirect: indexY4QFcRAMwqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4QFcRAMwqMeta?.name ?? "hotel-hotelSlug___fr",
    path: indexY4QFcRAMwqMeta?.path ?? "/fr/hotel/:hotelSlug()/",
    meta: indexY4QFcRAMwqMeta || {},
    alias: indexY4QFcRAMwqMeta?.alias || [],
    redirect: indexY4QFcRAMwqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4QFcRAMwqMeta?.name ?? "hotel-hotelSlug___es",
    path: indexY4QFcRAMwqMeta?.path ?? "/es/hotel/:hotelSlug()/",
    meta: indexY4QFcRAMwqMeta || {},
    alias: indexY4QFcRAMwqMeta?.alias || [],
    redirect: indexY4QFcRAMwqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY4QFcRAMwqMeta?.name ?? "hotel-hotelSlug___xx",
    path: indexY4QFcRAMwqMeta?.path ?? "/xx/hotel/:hotelSlug()/",
    meta: indexY4QFcRAMwqMeta || {},
    alias: indexY4QFcRAMwqMeta?.alias || [],
    redirect: indexY4QFcRAMwqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91offerSlug_93K9kdhYcjVqMeta?.name ?? "hotel-hotelSlug-offer-offerSlug___en",
    path: _91offerSlug_93K9kdhYcjVqMeta?.path ?? "/en/hotel/:hotelSlug()/offer/:offerSlug()/",
    meta: _91offerSlug_93K9kdhYcjVqMeta || {},
    alias: _91offerSlug_93K9kdhYcjVqMeta?.alias || [],
    redirect: _91offerSlug_93K9kdhYcjVqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/offer/[offerSlug].vue").then(m => m.default || m)
  },
  {
    name: _91offerSlug_93K9kdhYcjVqMeta?.name ?? "hotel-hotelSlug-offer-offerSlug___ru",
    path: _91offerSlug_93K9kdhYcjVqMeta?.path ?? "/ru/hotel/:hotelSlug()/offer/:offerSlug()/",
    meta: _91offerSlug_93K9kdhYcjVqMeta || {},
    alias: _91offerSlug_93K9kdhYcjVqMeta?.alias || [],
    redirect: _91offerSlug_93K9kdhYcjVqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/offer/[offerSlug].vue").then(m => m.default || m)
  },
  {
    name: _91offerSlug_93K9kdhYcjVqMeta?.name ?? "hotel-hotelSlug-offer-offerSlug___tr",
    path: _91offerSlug_93K9kdhYcjVqMeta?.path ?? "/tr/hotel/:hotelSlug()/offer/:offerSlug()/",
    meta: _91offerSlug_93K9kdhYcjVqMeta || {},
    alias: _91offerSlug_93K9kdhYcjVqMeta?.alias || [],
    redirect: _91offerSlug_93K9kdhYcjVqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/offer/[offerSlug].vue").then(m => m.default || m)
  },
  {
    name: _91offerSlug_93K9kdhYcjVqMeta?.name ?? "hotel-hotelSlug-offer-offerSlug___de",
    path: _91offerSlug_93K9kdhYcjVqMeta?.path ?? "/de/hotel/:hotelSlug()/offer/:offerSlug()/",
    meta: _91offerSlug_93K9kdhYcjVqMeta || {},
    alias: _91offerSlug_93K9kdhYcjVqMeta?.alias || [],
    redirect: _91offerSlug_93K9kdhYcjVqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/offer/[offerSlug].vue").then(m => m.default || m)
  },
  {
    name: _91offerSlug_93K9kdhYcjVqMeta?.name ?? "hotel-hotelSlug-offer-offerSlug___fr",
    path: _91offerSlug_93K9kdhYcjVqMeta?.path ?? "/fr/hotel/:hotelSlug()/offer/:offerSlug()/",
    meta: _91offerSlug_93K9kdhYcjVqMeta || {},
    alias: _91offerSlug_93K9kdhYcjVqMeta?.alias || [],
    redirect: _91offerSlug_93K9kdhYcjVqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/offer/[offerSlug].vue").then(m => m.default || m)
  },
  {
    name: _91offerSlug_93K9kdhYcjVqMeta?.name ?? "hotel-hotelSlug-offer-offerSlug___es",
    path: _91offerSlug_93K9kdhYcjVqMeta?.path ?? "/es/hotel/:hotelSlug()/offer/:offerSlug()/",
    meta: _91offerSlug_93K9kdhYcjVqMeta || {},
    alias: _91offerSlug_93K9kdhYcjVqMeta?.alias || [],
    redirect: _91offerSlug_93K9kdhYcjVqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/offer/[offerSlug].vue").then(m => m.default || m)
  },
  {
    name: _91offerSlug_93K9kdhYcjVqMeta?.name ?? "hotel-hotelSlug-offer-offerSlug___xx",
    path: _91offerSlug_93K9kdhYcjVqMeta?.path ?? "/xx/hotel/:hotelSlug()/offer/:offerSlug()/",
    meta: _91offerSlug_93K9kdhYcjVqMeta || {},
    alias: _91offerSlug_93K9kdhYcjVqMeta?.alias || [],
    redirect: _91offerSlug_93K9kdhYcjVqMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/hotel/[hotelSlug]/offer/[offerSlug].vue").then(m => m.default || m)
  },
  {
    name: indexSYQeRCDx2TMeta?.name ?? "index___en",
    path: indexSYQeRCDx2TMeta?.path ?? "/en/",
    meta: indexSYQeRCDx2TMeta || {},
    alias: indexSYQeRCDx2TMeta?.alias || [],
    redirect: indexSYQeRCDx2TMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSYQeRCDx2TMeta?.name ?? "index___ru",
    path: indexSYQeRCDx2TMeta?.path ?? "/ru/",
    meta: indexSYQeRCDx2TMeta || {},
    alias: indexSYQeRCDx2TMeta?.alias || [],
    redirect: indexSYQeRCDx2TMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSYQeRCDx2TMeta?.name ?? "index___tr",
    path: indexSYQeRCDx2TMeta?.path ?? "/tr/",
    meta: indexSYQeRCDx2TMeta || {},
    alias: indexSYQeRCDx2TMeta?.alias || [],
    redirect: indexSYQeRCDx2TMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSYQeRCDx2TMeta?.name ?? "index___de",
    path: indexSYQeRCDx2TMeta?.path ?? "/de/",
    meta: indexSYQeRCDx2TMeta || {},
    alias: indexSYQeRCDx2TMeta?.alias || [],
    redirect: indexSYQeRCDx2TMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSYQeRCDx2TMeta?.name ?? "index___fr",
    path: indexSYQeRCDx2TMeta?.path ?? "/fr/",
    meta: indexSYQeRCDx2TMeta || {},
    alias: indexSYQeRCDx2TMeta?.alias || [],
    redirect: indexSYQeRCDx2TMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSYQeRCDx2TMeta?.name ?? "index___es",
    path: indexSYQeRCDx2TMeta?.path ?? "/es/",
    meta: indexSYQeRCDx2TMeta || {},
    alias: indexSYQeRCDx2TMeta?.alias || [],
    redirect: indexSYQeRCDx2TMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSYQeRCDx2TMeta?.name ?? "index___xx",
    path: indexSYQeRCDx2TMeta?.path ?? "/xx/",
    meta: indexSYQeRCDx2TMeta || {},
    alias: indexSYQeRCDx2TMeta?.alias || [],
    redirect: indexSYQeRCDx2TMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IAASyQZ68gMeta?.name ?? "legal-page-slug___en",
    path: _91slug_93IAASyQZ68gMeta?.path ?? "/en/legal-page/:slug()/",
    meta: _91slug_93IAASyQZ68gMeta || {},
    alias: _91slug_93IAASyQZ68gMeta?.alias || [],
    redirect: _91slug_93IAASyQZ68gMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/legal-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IAASyQZ68gMeta?.name ?? "legal-page-slug___ru",
    path: _91slug_93IAASyQZ68gMeta?.path ?? "/ru/legal-page/:slug()/",
    meta: _91slug_93IAASyQZ68gMeta || {},
    alias: _91slug_93IAASyQZ68gMeta?.alias || [],
    redirect: _91slug_93IAASyQZ68gMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/legal-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IAASyQZ68gMeta?.name ?? "legal-page-slug___tr",
    path: _91slug_93IAASyQZ68gMeta?.path ?? "/tr/legal-page/:slug()/",
    meta: _91slug_93IAASyQZ68gMeta || {},
    alias: _91slug_93IAASyQZ68gMeta?.alias || [],
    redirect: _91slug_93IAASyQZ68gMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/legal-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IAASyQZ68gMeta?.name ?? "legal-page-slug___de",
    path: _91slug_93IAASyQZ68gMeta?.path ?? "/de/legal-page/:slug()/",
    meta: _91slug_93IAASyQZ68gMeta || {},
    alias: _91slug_93IAASyQZ68gMeta?.alias || [],
    redirect: _91slug_93IAASyQZ68gMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/legal-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IAASyQZ68gMeta?.name ?? "legal-page-slug___fr",
    path: _91slug_93IAASyQZ68gMeta?.path ?? "/fr/legal-page/:slug()/",
    meta: _91slug_93IAASyQZ68gMeta || {},
    alias: _91slug_93IAASyQZ68gMeta?.alias || [],
    redirect: _91slug_93IAASyQZ68gMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/legal-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IAASyQZ68gMeta?.name ?? "legal-page-slug___es",
    path: _91slug_93IAASyQZ68gMeta?.path ?? "/es/legal-page/:slug()/",
    meta: _91slug_93IAASyQZ68gMeta || {},
    alias: _91slug_93IAASyQZ68gMeta?.alias || [],
    redirect: _91slug_93IAASyQZ68gMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/legal-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IAASyQZ68gMeta?.name ?? "legal-page-slug___xx",
    path: _91slug_93IAASyQZ68gMeta?.path ?? "/xx/legal-page/:slug()/",
    meta: _91slug_93IAASyQZ68gMeta || {},
    alias: _91slug_93IAASyQZ68gMeta?.alias || [],
    redirect: _91slug_93IAASyQZ68gMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/legal-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: maintenance8PnTKBquPDMeta?.name ?? "maintenance___en",
    path: maintenance8PnTKBquPDMeta?.path ?? "/en/maintenance/",
    meta: maintenance8PnTKBquPDMeta || {},
    alias: maintenance8PnTKBquPDMeta?.alias || [],
    redirect: maintenance8PnTKBquPDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenance8PnTKBquPDMeta?.name ?? "maintenance___ru",
    path: maintenance8PnTKBquPDMeta?.path ?? "/ru/maintenance/",
    meta: maintenance8PnTKBquPDMeta || {},
    alias: maintenance8PnTKBquPDMeta?.alias || [],
    redirect: maintenance8PnTKBquPDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenance8PnTKBquPDMeta?.name ?? "maintenance___tr",
    path: maintenance8PnTKBquPDMeta?.path ?? "/tr/maintenance/",
    meta: maintenance8PnTKBquPDMeta || {},
    alias: maintenance8PnTKBquPDMeta?.alias || [],
    redirect: maintenance8PnTKBquPDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenance8PnTKBquPDMeta?.name ?? "maintenance___de",
    path: maintenance8PnTKBquPDMeta?.path ?? "/de/maintenance/",
    meta: maintenance8PnTKBquPDMeta || {},
    alias: maintenance8PnTKBquPDMeta?.alias || [],
    redirect: maintenance8PnTKBquPDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenance8PnTKBquPDMeta?.name ?? "maintenance___fr",
    path: maintenance8PnTKBquPDMeta?.path ?? "/fr/maintenance/",
    meta: maintenance8PnTKBquPDMeta || {},
    alias: maintenance8PnTKBquPDMeta?.alias || [],
    redirect: maintenance8PnTKBquPDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenance8PnTKBquPDMeta?.name ?? "maintenance___es",
    path: maintenance8PnTKBquPDMeta?.path ?? "/es/maintenance/",
    meta: maintenance8PnTKBquPDMeta || {},
    alias: maintenance8PnTKBquPDMeta?.alias || [],
    redirect: maintenance8PnTKBquPDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenance8PnTKBquPDMeta?.name ?? "maintenance___xx",
    path: maintenance8PnTKBquPDMeta?.path ?? "/xx/maintenance/",
    meta: maintenance8PnTKBquPDMeta || {},
    alias: maintenance8PnTKBquPDMeta?.alias || [],
    redirect: maintenance8PnTKBquPDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935yhvTgs9KDMeta?.name ?? "other-page-slug___en",
    path: _91slug_935yhvTgs9KDMeta?.path ?? "/en/other-page/:slug()/",
    meta: _91slug_935yhvTgs9KDMeta || {},
    alias: _91slug_935yhvTgs9KDMeta?.alias || [],
    redirect: _91slug_935yhvTgs9KDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/other-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935yhvTgs9KDMeta?.name ?? "other-page-slug___ru",
    path: _91slug_935yhvTgs9KDMeta?.path ?? "/ru/other-page/:slug()/",
    meta: _91slug_935yhvTgs9KDMeta || {},
    alias: _91slug_935yhvTgs9KDMeta?.alias || [],
    redirect: _91slug_935yhvTgs9KDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/other-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935yhvTgs9KDMeta?.name ?? "other-page-slug___tr",
    path: _91slug_935yhvTgs9KDMeta?.path ?? "/tr/other-page/:slug()/",
    meta: _91slug_935yhvTgs9KDMeta || {},
    alias: _91slug_935yhvTgs9KDMeta?.alias || [],
    redirect: _91slug_935yhvTgs9KDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/other-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935yhvTgs9KDMeta?.name ?? "other-page-slug___de",
    path: _91slug_935yhvTgs9KDMeta?.path ?? "/de/other-page/:slug()/",
    meta: _91slug_935yhvTgs9KDMeta || {},
    alias: _91slug_935yhvTgs9KDMeta?.alias || [],
    redirect: _91slug_935yhvTgs9KDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/other-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935yhvTgs9KDMeta?.name ?? "other-page-slug___fr",
    path: _91slug_935yhvTgs9KDMeta?.path ?? "/fr/other-page/:slug()/",
    meta: _91slug_935yhvTgs9KDMeta || {},
    alias: _91slug_935yhvTgs9KDMeta?.alias || [],
    redirect: _91slug_935yhvTgs9KDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/other-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935yhvTgs9KDMeta?.name ?? "other-page-slug___es",
    path: _91slug_935yhvTgs9KDMeta?.path ?? "/es/other-page/:slug()/",
    meta: _91slug_935yhvTgs9KDMeta || {},
    alias: _91slug_935yhvTgs9KDMeta?.alias || [],
    redirect: _91slug_935yhvTgs9KDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/other-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935yhvTgs9KDMeta?.name ?? "other-page-slug___xx",
    path: _91slug_935yhvTgs9KDMeta?.path ?? "/xx/other-page/:slug()/",
    meta: _91slug_935yhvTgs9KDMeta || {},
    alias: _91slug_935yhvTgs9KDMeta?.alias || [],
    redirect: _91slug_935yhvTgs9KDMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/other-page/[slug].vue").then(m => m.default || m)
  },
  {
    name: searchkZZbnzUBeCMeta?.name ?? "search___en",
    path: searchkZZbnzUBeCMeta?.path ?? "/en/search/",
    meta: searchkZZbnzUBeCMeta || {},
    alias: searchkZZbnzUBeCMeta?.alias || [],
    redirect: searchkZZbnzUBeCMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchkZZbnzUBeCMeta?.name ?? "search___ru",
    path: searchkZZbnzUBeCMeta?.path ?? "/ru/%D0%BF%D0%BE%D0%B8%D1%81%D0%BA/",
    meta: searchkZZbnzUBeCMeta || {},
    alias: searchkZZbnzUBeCMeta?.alias || [],
    redirect: searchkZZbnzUBeCMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchkZZbnzUBeCMeta?.name ?? "search___tr",
    path: searchkZZbnzUBeCMeta?.path ?? "/tr/arama/",
    meta: searchkZZbnzUBeCMeta || {},
    alias: searchkZZbnzUBeCMeta?.alias || [],
    redirect: searchkZZbnzUBeCMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchkZZbnzUBeCMeta?.name ?? "search___de",
    path: searchkZZbnzUBeCMeta?.path ?? "/de/suche/",
    meta: searchkZZbnzUBeCMeta || {},
    alias: searchkZZbnzUBeCMeta?.alias || [],
    redirect: searchkZZbnzUBeCMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchkZZbnzUBeCMeta?.name ?? "search___fr",
    path: searchkZZbnzUBeCMeta?.path ?? "/fr/recherche/",
    meta: searchkZZbnzUBeCMeta || {},
    alias: searchkZZbnzUBeCMeta?.alias || [],
    redirect: searchkZZbnzUBeCMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchkZZbnzUBeCMeta?.name ?? "search___es",
    path: searchkZZbnzUBeCMeta?.path ?? "/es/search/",
    meta: searchkZZbnzUBeCMeta || {},
    alias: searchkZZbnzUBeCMeta?.alias || [],
    redirect: searchkZZbnzUBeCMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchkZZbnzUBeCMeta?.name ?? "search___xx",
    path: searchkZZbnzUBeCMeta?.path ?? "/xx/search/",
    meta: searchkZZbnzUBeCMeta || {},
    alias: searchkZZbnzUBeCMeta?.alias || [],
    redirect: searchkZZbnzUBeCMeta?.redirect,
    component: () => import("/usr/src/app/packages/all-inclusive-app/pages/search.vue").then(m => m.default || m)
  }
]