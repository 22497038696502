<template>
  <layout hide-footer>
    <div class="Error">
      <div class="Error-content">
        <ai-typo variant="heading-01" as="h1">
          {{ $t('error.page.title') }}
        </ai-typo>

        <ai-typo variant="heading-03" as="h2">
          {{ $t('error.page.subtitle') }}
        </ai-typo>

        <ai-typo variant="paragraph-03-regular" as="p">
          {{ $t('error.page.text') }}
        </ai-typo>

        <div class="Error-actions">
          <ai-button
            variant="secondary"
            :label="$t('error.page.goBack')"
            class="Error-actions-action"
            @click="goBack" />
          <ai-button
            :label="$t('error.page.refresh')"
            class="Error-actions-action"
            @click="onRefresh" />
        </div>
      </div>
    </div>
  </layout>
</template>

<script setup lang="ts">
type Props = {
  error: Record<string, unknown> | string;
};

const props = defineProps<Props>();

// eslint-disable-next-line no-console
console.error(props.error);

function onRefresh() {
  clearError();
  window.location.reload();
}

function goBack() {
  clearError();
  window.history.back();
}

const windowListener = () => clearError();

onMounted(() => {
  window.addEventListener('beforeunload', windowListener);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', windowListener);
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';

.Error {
  margin-top: constants.$header-large;
}

.Error-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: constants.$margin-01;
  background-color: colors.$neutral-100;
  min-height: calc(100dvh - constants.$header-large);
}

.Error-actions {
  margin-top: constants.$margin-01;
}

.Error-actions-action {
  &:not(:last-of-type) {
    margin-right: constants.$margin-01;
  }
}
</style>
