const UI_CUSTOMIZATION = {
  brand: {
    logoSrc: '', // Lazy logo hydration since it rely on origin
    // See Valtech logo
    logoWidth: 300,
    name: 'ALLINCLUSIVE', // See Valtech logo
  },
  buttonRegisterDisplay: 'true',
  cancelButtonDisplay: 'true',
  lcahLoginLinkDisplay: 'true',
  lcahOptinDefault: 'true',
  lcahOptinDisplay: 'true',
  newsLettersOptinCodes: ['ALL'],
  newsLettersOptinDefault: 'false',
  newsLettersOptinDisplay: 'true',
  rememberMeDisplay: 'true',
  socialNetworkDisplay: 'true',
  styles: {
    cancelButtonBackgroundColor: '#fff',
    cancelButtonColor: '#736335',

    primaryButtonBackgroundColor: '#736335',
    primaryButtonColor: '#ffffff',

    secondaryButtonBackgroundColor: '#736335',
    secondaryButtonColor: '#ffffff',
  },
};

export function accorLoginSSO(
  accorAuthenticationurl: string,
  nuxtPublicUrl: string,
  locale: string,
  returnUrl = false,
  state?: Record<string, unknown>,
) {
  const redirectionURL = getRedirectUrl(
    accorAuthenticationurl,
    nuxtPublicUrl,
    locale,
    state,
  );

  if (returnUrl) {
    return redirectionURL.toString();
  }

  window.open(redirectionURL.toString());
}

export function accorRegisterSSO(
  accorAuthenticationurl: string,
  nuxtPublicUrl: string,
  locale: string,
  returnUrl = false,
  state?: Record<string, unknown>,
) {
  const redirectionURL = getRedirectUrl(
    accorAuthenticationurl,
    nuxtPublicUrl,
    locale,
    state,
  );

  redirectionURL.searchParams.append('accorregister', 'true');

  if (returnUrl) {
    return redirectionURL.toString();
  }

  window.open(redirectionURL.toString());
}

export function getRedirectUrl(
  accorAuthenticationUrl: string,
  nuxtPublicUrl: string,
  locale: string,
  state?: Record<string, unknown>,
  register?: boolean,
): URL {
  const redirectionURL = new URL(
    `/as/authorization.oauth2`,
    accorAuthenticationUrl,
  );

  UI_CUSTOMIZATION.brand.logoSrc = `${nuxtPublicUrl}/assets/logo-dark.png`;

  redirectionURL.searchParams.append('client_id', 'com.allinclusive');
  redirectionURL.searchParams.append('response_type', 'code');
  redirectionURL.searchParams.append('scope', 'openid');
  redirectionURL.searchParams.append('ui', JSON.stringify(UI_CUSTOMIZATION));

  const redirectURI = new URL('/login/redirect', nuxtPublicUrl);

  if (state) {
    redirectionURL.searchParams.append('state', JSON.stringify(state));
  }

  if (register) {
    redirectionURL.searchParams.append('accorregister', 'true');
  }

  redirectionURL.searchParams.append('redirect_uri', redirectURI.toString());
  redirectionURL.searchParams.append('ui_locales', locale);

  return redirectionURL;
}
