<template>
  <div
    class="AccountSubmenuLogged"
    :class="{ 'AccountSubmenuLogged--inverse': inverse }">
    <div class="AccountSubmenuLogged-content">
      <button
        v-if="!hideClose"
        class="AccountSubmenuLogged-closeButton"
        :aria-label="$t('ux.molecules.modal.close.label')"
        @click="$emit('close')">
        <ai-icon
          name="close"
          class="AccountSubmenuLogged-closeButtonIcon"
          :class="{
            'AccountSubmenuLogged-closeButtonIcon--inverse': inverse,
          }" />
      </button>

      <ai-typo
        class="AccountSubmenuLogged-hello"
        variant="heading-04"
        as="h4"
        :color="inverse ? 'white' : 'stratos-900'">
        {{ $t('accountSubmenu.hello', { lastName, firstName }) }}
      </ai-typo>

      <ai-typo
        class="AccountSubmenuLogged-memberStatus"
        variant="legal-regular"
        as="p"
        :color="inverse ? 'neutral-100' : 'stratos-800'">
        <span>{{ $t('accountSubmenu.memberStatus') }}</span>
        <span class="bold">{{ userStore.loyaltyCardLabel }}</span>
      </ai-typo>

      <ai-typo
        class="AccountSubmenuLogged-rewardPoints"
        variant="legal-regular"
        as="p"
        :color="inverse ? 'neutral-100' : 'stratos-800'">
        {{ $t('accountSubmenu.rewardPoints') }}
        <span class="bold">{{ rewardPoints }}</span>
      </ai-typo>

      <div>
        <ai-button
          class="AccountSubmenuLogged-manageAccount"
          :inverse="inverse"
          icon-right="button-arrow-right"
          variant="tertiary"
          :label="$t('accountSubmenu.manageAccount')"
          as-link
          target="_blank"
          :url="manageAccountUrl" />
      </div>

      <div>
        <ai-button
          class="AccountSubmenuLogged-myBookings"
          :inverse="inverse"
          icon-right="button-arrow-right"
          variant="tertiary"
          :label="$t('accountSubmenu.myBookings')"
          as-link
          target="_blank"
          :url="myBookingsUrl" />
      </div>

      <div>
        <ai-button
          class="AccountSubmenuLogged-myPointsHistory"
          :inverse="inverse"
          icon-right="button-arrow-right"
          variant="tertiary"
          :label="$t('accountSubmenu.myPointsHistory')"
          as-link
          target="_blank"
          :url="myPointsHistoryUrl" />
      </div>
    </div>
    <div class="AccountSubmenuLogged-cta">
      <ai-button
        data-testid="myAccountLogout"
        :inverse="inverse"
        variant="tertiary"
        :label="$t('accountSubmenu.logout')"
        :full-width="true"
        @click="logout" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '~~/domains/user';
import { eventCustom } from '~~/helpers/gtm.helper';

const { locale } = useI18n();
const userStore = useUserStore();
const config = useRuntimeConfig();

const rewardPoints = computed(
  () => userStore.user.loyalty?.balances?.nbPoints ?? 0,
);

const civilInformation = computed(() => userStore.civilInformation);
const firstName = computed(() => civilInformation.value?.firstName);
const lastName = computed(() => civilInformation.value?.lastName);

const manageAccountUrl = `${config.public.allBaseUrl}/account/index.${locale.value}.shtml#`;
const myBookingsUrl = `${config.public.allBaseUrl}/account/index.${locale.value}.shtml#/my-bookings`;
const myPointsHistoryUrl = `${config.public.allBaseUrl}/account/index.${locale.value}.shtml#/rewards`;

const logout = () => {
  eventCustom('Generic Event', { event_name: 'logout' });
  userStore.logout();
};

type Props = {
  inverse?: boolean;
  hideClose?: boolean;
};

type Emits = {
  (event: 'close'): void;
};

defineProps<Props>();
defineEmits<Emits>();
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/functions' as func;

.AccountSubmenuLogged {
  background-color: colors.$white;
  border: 1px solid colors.$gold-200;

  &--inverse {
    background-color: colors.$neutral-800;
  }
}
.AccountSubmenuLogged-content {
  position: relative;
  min-width: func.calcRem(360);
  padding: constants.$padding-03 constants.$padding-02;
}

.AccountSubmenuLogged-closeButton {
  position: absolute;
  right: constants.$margin-02;
  top: constants.$margin-02;
  fill: colors.$gold-300;
}

.AccountSubmenuLogged-closeButtonIcon {
  fill: colors.$gold-900;

  &--inverse {
    fill: colors.$gold-300;
  }
}

.AccountSubmenuLogged-rewardPoints {
  margin-bottom: constants.$margin-02;
}

.AccountSubmenuLogged-manageAccount,
.AccountSubmenuLogged-myBookings,
.AccountSubmenuLogged-myPointsHistory {
  display: inline-block;
}

.AccountSubmenuLogged-manageAccount,
.AccountSubmenuLogged-myBookings {
  margin-bottom: constants.$margin-02;
}

.AccountSubmenuLogged-cta {
  padding: constants.$padding-01;

  border-top: 1px solid colors.$gold-200;
}
</style>
