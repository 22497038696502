<template>
  <ai-footer
    :columns="footerData.footerColumns ?? []"
    :contact-us="contactUsData"
    :social-media="footerData.socialLinks ?? []"
    :brand-logos="footerData.footerBrandLogos ?? []"
    :links="footerData.footerLinks ?? []"
    :copyright="footerData.copyright" />
</template>

<script setup lang="ts">
import type {
  ContactUsFragment,
  FooterConfigurationFragment,
} from '~~/domains/graphql';
import { useGlobalConfigurationAsyncData } from '~~/domains/graphql';

const { data: globalConfigurationData } = useGlobalConfigurationAsyncData();

const footerData = computed<FooterConfigurationFragment>(() => {
  return (
    globalConfigurationData.value?.globalConfiguration?.footer ?? {
      copyright: '',
    }
  );
});
const contactUsData = computed<ContactUsFragment>(() => {
  return globalConfigurationData.value?.globalConfiguration?.contactUs ?? {};
});
</script>
