import type { UnconfiguredGuests } from '~~/domains/booking';
import { serializeRemainingGuests } from '~~/domains/booking';

export function useBookingUrlGenerator() {
  const localePath = useLocalePath();

  const buildQueryParameters = (
    roomsAndGuests: Array<UnconfiguredGuests>,
    ridcode: string,
    dateIn: string,
    nights: number,
  ) => {
    return {
      dateIn,
      nights: String(nights),
      ridcode,
      roomCount: String(roomsAndGuests.length),
      remainingGuests: serializeRemainingGuests(roomsAndGuests),
    };
  };

  const bookingURL = (
    roomsAndGuests: Array<UnconfiguredGuests>,
    ridcode: string,
    dateIn: string,
    nights: number,
  ) => {
    return localePath({
      name: 'booking-ridcode-room',
      params: { ridcode },
      query: buildQueryParameters(roomsAndGuests, ridcode, dateIn, nights),
    });
  };

  return {
    buildQueryParameters,
    bookingURL,
  };
}
