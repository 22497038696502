<template>
  <Teleport to="body">
    <div
      v-show="visible"
      ref="trapRef"
      class="AiPopin"
      :class="{ 'AiPopin--active': modelValue }"
      role="alertdialog"
      aria-modal="true"
      @click="onOverlayClick">
      <div
        ref="container"
        class="AiPopin-overlay"
        :class="{ 'AiPopin-overlay--active': modelValue }" />

      <div class="AiPopin-wrapper" :class="{ 'AiPopin-wrapper--full': full }">
        <div
          ref="body"
          class="AiPopin-body"
          :class="[
            `AiPopin-body--${contentAlign}`,
            { 'AiPopin-Drawer': drawerPopin },
          ]">
          <slot />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useFocusTrap } from '~~/helpers/modal.helper';

type Props = {
  contentAlign?: 'left' | 'center' | 'right' | 'top';
  modelValue: boolean;
  overlayListener?: boolean;
  full?: boolean;
  drawerPopin?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: boolean): void;
  (event: 'overlay-click'): void;
  (event: 'popin-close'): void;
};

const props = withDefaults(defineProps<Props>(), {
  contentAlign: 'center',
});
const emits = defineEmits<Emits>();

const container = ref<HTMLElement>();
const body = ref<HTMLElement>();

const { trapRef } = useFocusTrap();

const visible = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});

function preventDefault(event: Event) {
  event.preventDefault();
}

function stopPropagation(event: Event) {
  event.stopPropagation();
}

function disableScroll(disabled: boolean) {
  if (disabled) {
    container.value?.addEventListener('scroll', preventDefault);
    container.value?.addEventListener('wheel', preventDefault);
    container.value?.addEventListener('touchmove', preventDefault);
  } else {
    container.value?.removeEventListener('scroll', preventDefault);
    container.value?.removeEventListener('wheel', preventDefault);
    container.value?.removeEventListener('touchmove', preventDefault);
  }
}

function handleEscapeKey(event: KeyboardEvent) {
  if (visible.value && event.key === 'Escape') {
    onOverlayClick();
  }
}

onMounted(() => {
  if (visible.value) {
    disableScroll(true);
  }

  if (props.overlayListener) {
    (body.value?.childNodes ?? []).forEach(node => {
      node.addEventListener('click', stopPropagation);
    });
  }

  if (process.client) {
    document.addEventListener('keydown', handleEscapeKey);
  }
});
onBeforeUnmount(() => {
  disableScroll(false);

  if (props.overlayListener) {
    (body.value?.childNodes ?? []).forEach(node => {
      node.removeEventListener('click', stopPropagation);
    });
  }

  if (process.client) {
    document.removeEventListener('keydown', handleEscapeKey);
  }
});

const onOverlayClick = () => {
  emits('overlay-click');
  emits('popin-close');
};

watch(visible, value => disableScroll(value));
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';

.AiPopin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;

  &--active {
    z-index: 120;
  }
}

.AiPopin-Drawer {
  height: 100dvh;
}
.AiPopin-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: colors.$neutral-900;
  opacity: 0;
  transition: opacity 500ms;

  &--active {
    opacity: 0.8;
  }
}

.AiPopin-wrapper {
  position: absolute;
  top: constants.$inner-05;
  bottom: constants.$inner-05;
  left: constants.$inner-05;
  right: constants.$inner-05;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;

  &--full {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.AiPopin-body {
  position: relative;
  width: 100%;
  display: flex;

  &--left,
  &--top {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }
}
</style>
