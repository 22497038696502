import type { RouteLocationNormalized } from 'vue-router';

const LANGUAGE_COOKIE = 'i18n_redirected';

function getLanguageFromRoute(to: RouteLocationNormalized): string | undefined {
  const { fullPath } = to;
  const regex = /^\/?([a-z]{2})\//;

  const matches = regex.exec(fullPath);

  if (!matches) return;

  const [, language] = matches;

  return language;
}

export default defineNuxtRouteMiddleware(to => {
  // We can't use i18n exposed functions as the plugin is not loaded when this triggers
  const languageCookie = useCookie(LANGUAGE_COOKIE);

  if (!process.client) return;

  const languageRoute = getLanguageFromRoute(to);

  if (languageCookie.value !== languageRoute) {
    languageCookie.value = languageRoute;
  }
});
