<template>
  <picture v-if="src && srcSet" class="AiImage">
    <source :srcset="srcSet" :sizes="sizes" type="image/webp" />
    <img
      :alt="alt ?? ''"
      class="AiImage"
      :fetchpriority="fetchPriority"
      :loading="loading"
      :sizes="sizes"
      :srcset="srcSet" />
  </picture>
</template>

<script setup lang="ts">
import type { Maybe } from 'graphql/jsutils/Maybe';

import { breakpoints } from '~~/helpers';

interface ImageProps {
  src?: string | Maybe<string>;
  srcSet?: string;
  alt?: string;
  decorative?: boolean;
  fetchPriority?: 'high' | 'low' | 'auto';
  loading?: 'lazy' | 'eager';
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  height?: number | string;
}

const props = withDefaults(defineProps<ImageProps>(), {
  alt: '',
  decorative: false,
  fetchPriority: 'low',
  lg: 100,
  loading: 'eager',
  md: 100,
  sizes: undefined,
  sm: 100,
  src: undefined,
  srcSet: '',
  xl: 100,
  xs: 100,
  height: '100%',
});

const sizes = computed(() => {
  const sizesCollection = [];

  for (const size of Object.keys(breakpoints) as [
    'xs' | 'sm' | 'md' | 'lg' | 'xl',
  ]) {
    sizesCollection.push(
      `(max-width: ${breakpoints[size]}px) ${props[size]}vw`,
    );
  }

  // Default size
  sizesCollection.push(`${props.xl}vw`);

  return sizesCollection.join(', ');
});
</script>

<style lang="scss">
.AiImage {
  object-fit: cover;
  object-position: center;
  height: v-bind(height);
  min-width: 100%;
  width: 100%;
}
</style>
