import type { Directive } from 'vue';

const listenerMap: Record<string, (event: MouseEvent) => void> = {};

export const directive: Directive = {
  beforeUnmount(_element: unknown, _binding: unknown, vnode: globalThis.VNode) {
    if (!vnode.scopeId) return;

    if (typeof listenerMap[vnode.scopeId] !== 'function') return;

    document.removeEventListener('click', listenerMap[vnode.scopeId], {
      capture: true,
    });
  },
  created(element: HTMLElement, binding, vnode) {
    if (!vnode.scopeId) return;

    const callback = binding.value;

    listenerMap[vnode.scopeId] = event => {
      if (!element || typeof callback !== 'function') return;

      const isInside = element.contains(event.target as Node);

      if (!isInside) callback(event);
    };

    document.addEventListener('click', listenerMap[vnode.scopeId], {
      capture: true,
    });
  },
};
