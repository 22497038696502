import { onError } from '@apollo/client/link/error';
import type { Router } from 'vue-router';

import type { LocalePathFunction } from '~~/helpers/redirect.interface';

export const errorLink = (router: Router, localePath: LocalePathFunction) =>
  onError(error => {
    if (!error.networkError) return;

    const statusCode = (
      error.networkError as unknown as { statusCode?: number }
    )?.statusCode as number;

    if (statusCode === 593) {
      router.push(localePath({ name: 'maintenance' }));
    }
  });
