import { REFRESH_PRICES_DURATION_MAP } from '../constants';

import { Environment } from '~~/core';

interface RefreshPricesDuration {
  inMilliseconds: number;
  inSeconds: number;
  inMinutes: number;
}

/**
 * Returns the duration to refresh the booking prices in milliseconds, seconds and minutes.
 */
export function useRefreshPricesDuration(): RefreshPricesDuration {
  const config = useRuntimeConfig();
  const env = config.public.appEnv as Environment;

  const durationInMinutes = REFRESH_PRICES_DURATION_MAP.get(env);

  if (!durationInMinutes) {
    throw new Error(`No refresh prices duration found for environment: ${env}`);
  }

  const durationInSeconds = durationInMinutes * 60;
  const durationInMilliseconds = durationInSeconds * 1000;

  return {
    inMilliseconds: durationInMilliseconds,
    inSeconds: durationInSeconds,
    inMinutes: durationInMinutes,
  };
}
