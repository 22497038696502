import { decodeJwt } from 'jose';

export default defineNuxtRouteMiddleware((_to, _from) => {
  // We want this middleware to run only on SSR for now
  if (process.client) return;

  const nuxtConfig = useRuntimeConfig();

  const requestEvent = useRequestEvent();
  const headers = requestEvent?.node.req.headers;

  if (!headers || !headers.cookie) {
    return;
  }

  const rawCookie = headers.cookie;
  const cookies = rawCookie
    .split('; ')
    .reduce<Record<string, string>>((accumulator, item) => {
      const [key, ...value] = item.split('=');
      accumulator[key] = value.join('=');

      return accumulator;
    }, {});

  if (!cookies.access_token) {
    return;
  }

  const decodedToken = decodeJwt(cookies.access_token);

  if (!decodedToken || !decodedToken.exp) {
    return;
  }

  const expiry = decodedToken.exp;
  const now = Math.round(new Date().getTime() / 1000);

  // Expires
  if (expiry > now) {
    return;
  }

  navigateTo(
    `${nuxtConfig.public.baseUrl}/login/refresh?withRedirect=true&from=${
      requestEvent.node.req.url
        ? encodeURIComponent(requestEvent.node.req.url)
        : '/'
    }`,
    { external: true },
  );
});
