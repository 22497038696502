import axios from 'axios';

import { getRawBackendUrl } from '~~/helpers/backend-url.helper';

export default defineNuxtRouteMiddleware(async to => {
  if (!to.path.includes('maintenance')) return;

  const serverUri = getRawBackendUrl();
  const localePath = useLocalePath();

  try {
    const response = await axios.get(
      `${serverUri}/maintenance/is-under-maintenance`,
    );

    const isUnderMaintenance = response.data;

    if (!isUnderMaintenance) {
      return navigateTo(localePath({ name: 'index' }));
    }
  } catch (err) {
    return false;
  }
});
