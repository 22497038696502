<template>
  <NuxtPage />
</template>

<script lang="ts" setup>
import type { ReactiveHead } from '@unhead/vue';

import { Environment } from '~/core';

import {
  usePreloadCurrencies,
  usePreloadLanguages,
} from './domains/referential';

import { eventCustom } from '~~/helpers/gtm.helper';

const runtimeConfig = useRuntimeConfig();

useAppHistory();

const { locale } = useI18n();
const route = useRoute();
const isPreview = computed(() => route.query.draft === 'true');

const head = computed<ReactiveHead>(() => {
  const meta = [
    {
      name: 'google-site-verification',
      content: 'QFTRdQRgXFSTOfNL5wYw56SzYdbHzGPfEp7M131P0us',
    },
  ];

  if (isPreview.value) {
    meta.push(
      { name: 'robots', content: 'noindex, nofollow' },
      { name: 'googlebot', content: 'noindex, nofollow' },
    );
  }

  const script: ReactiveHead['script'] = [
    {
      async: false,
      'data-document-language': true,
      'data-domain-script': runtimeConfig.public.oneTrustToken,
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    },
    {
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.defer=true;j.src=
          'https://sgtm.allinclusive-collection.com/ixzn81lqx6jltmk.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KBV232K');`,
    },
  ];

  if (
    [Environment.Pro.toString(), Environment.Oat.toString()].includes(
      runtimeConfig.public.appEnv,
    )
  ) {
    script.push({
      children: `!function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){
          n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];
          t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];
          z.parentNode.insertBefore(t,z)}(window,document,'script',
          'https://tags.srv.stackadapt.com/events.js');saq('ts', '5F9WZpmgMtJZ5OeKQ5S3RA');`,
    });
  }

  if (process.env.NEW_RELIC_ENABLED === 'true') {
    script.push({ src: `/newrelic.${process.env.NUXT_PUBLIC_APP_ENV}.js` });
  }

  return {
    htmlAttrs: {
      lang: locale.value,
    },
    script,
    meta,
  };
});

useHead(head);

useIdentificationPlaintext();

await usePreloadCurrencies();
await usePreloadLanguages();

if (route.query?.type === 'login') {
  eventCustom('Generic Event', { event_name: 'login' });
}
</script>

<style lang="scss">
@import '@/assets/styles/main';
@import '@/assets/styles/print';
</style>
