import { Environment } from '~~/core';

const LOCALIZATION_HEADER = 'cloudfront-viewer-country';
const DBEUG_LOCALIZATION_HEADER = 'x-debug-viewer-country';

export function useLocalizationHeader() {
  const nuxtApp = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();

  return computed<string | null | undefined>(() => {
    const debugHeader =
      nuxtApp.payload.data.headers[DBEUG_LOCALIZATION_HEADER] || null;
    const header = nuxtApp.payload.data.headers[LOCALIZATION_HEADER] || null;

    if (runtimeConfig.public.appEnv !== Environment.Pro)
      return debugHeader || header;

    return header;
  });
}
