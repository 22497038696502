import axios from 'axios';
import { decodeJwt } from 'jose';
import { defineStore } from 'pinia';
import type { RouteLocationRaw } from 'vue-router';

import {
  UserDocument,
  UserQuery,
  type Card,
  type CustomerEmailContactFragment,
  type CustomerInformation,
  type CustomerPhoneNumberFragment,
  type CustomerPostalAddressFragment,
  type IndividualName,
  type Maybe,
} from '~/domains/graphql';
import { event } from '~/helpers/gtm.helper';

export type State = {
  _accessToken: null | string;
  _decodedAccessToken: null | Record<string, unknown>;
  _accessTokenExpiration: null | number;
  hasLoadedUser: boolean;
  refreshTokenPromise: {
    loading: boolean;
    expiredAt: number;
    delay: number;
  };
  user: CustomerInformation;
};

export type Getters = {
  accessToken(): null | string;
  accessTokenExpiration(): null | number;
  activeLoyaltyCard(): Maybe<Card> | undefined;
  billingAddress(): Maybe<CustomerPostalAddressFragment> | undefined;
  civilInformation(): Maybe<IndividualName> | undefined;
  emailContact(): Maybe<CustomerEmailContactFragment> | undefined;
  isAccessTokenExpired(): boolean;
  isRefreshTokenLoading(): boolean;
  isLoyalUser(): boolean;
  loyaltyCardCode(): string;
  loyaltyCardLabel(): string;
  loyaltyCardNumber(): string;
  loyaltyStatus(): string;
  phoneNumber(): Maybe<CustomerPhoneNumberFragment> | undefined;
  primaryAddress(): Maybe<CustomerPostalAddressFragment> | undefined;
  isLogged(): boolean;
  preferentialCode(): string | null;
};

export type Actions = {
  loadAccessToken(): void;
  loadUser(): void;
  lockAccessTokenRefresh(): void;
  logout(): Promise<void>;
  refreshAccessToken(): Promise<void>;
  sendLoginEvent(): void;
  unlockAccessTokenRefresh(): void;
};

export const useUserStore = defineStore<'user', State, Getters, Actions>(
  'user',
  {
    actions: {
      loadAccessToken() {
        this._accessToken = useCookie('access_token').value ?? null;
        this._decodedAccessToken = this._accessToken
          ? decodeJwt(this._accessToken)
          : null;
        this._accessTokenExpiration = this._accessToken
          ? (this._decodedAccessToken?.exp as number)
          : null;
      },
      async loadUser() {
        if (process.server) return;

        try {
          const result =
            await useNuxtApp().$apollo.defaultClient.query<UserQuery>({
              query: UserDocument,
            });

          this.user = result.data?.user ?? {};
        } catch (error) {
          //
        }

        this.hasLoadedUser = true;
      },
      lockAccessTokenRefresh() {
        this.refreshTokenPromise.loading = true;
        this.refreshTokenPromise.expiredAt =
          new Date().getTime() + this.refreshTokenPromise.delay;
      },
      async logout() {
        const router = useRouter();
        const route = useRoute();
        const { getLatestRouteMatching } = useAppHistory();

        const {
          query: { asAnonymous },
        } = route;
        const response = await axios.get('/logout');

        if (response.status === 200) {
          this.user = {};

          if (asAnonymous === 'false') {
            const latestMatching = getLatestRouteMatching(
              'booking-ridcode-rate',
            );

            await router.replace(latestMatching as RouteLocationRaw);
          }

          window.location.reload();
        }
      },
      async refreshAccessToken() {
        if (!this.accessToken || this.isRefreshTokenLoading) {
          return;
        }

        this.lockAccessTokenRefresh();

        await axios.get('/login/refresh');

        this.loadAccessToken();

        this.unlockAccessTokenRefresh();
      },
      sendLoginEvent() {
        const route = useRoute();

        const isRedirectedFromLogin = ['signup', 'login'].includes(
          route.query.type as string,
        );

        if (this.isLogged && isRedirectedFromLogin) {
          event('login', {
            category: 'authentification',
            action:
              route.query.type === 'signup'
                ? 'sign up - click on cta'
                : 'sign in - click on cta',
            label: route.query.type === 'signup' ? 'sign up' : 'connect',
          });
        }
      },
      unlockAccessTokenRefresh() {
        this.refreshTokenPromise.loading = false;
        this.refreshTokenPromise.expiredAt = 0;
      },
    },
    getters: {
      accessToken() {
        if (!this._accessToken || this.isAccessTokenExpired) {
          this._accessToken = useCookie('identification-token').value ?? null;
          this._decodedAccessToken = this._accessToken
            ? decodeJwt(this._accessToken)
            : null;
          this._accessTokenExpiration = this._accessToken
            ? (this._decodedAccessToken?.exp as number)
            : null;
        }

        return this._accessToken;
      },
      accessTokenExpiration() {
        return this._accessTokenExpiration;
      },
      activeLoyaltyCard() {
        return this.user.loyalty?.loyaltyCards?.card?.find(
          card => card?.isLastActiveCard,
        );
      },
      billingAddress(): Maybe<CustomerPostalAddressFragment> | undefined {
        return this.user.contactMediums?.contactMedium?.find(contactMedium =>
          contactMedium.mediumUsages?.mediumUsage?.some(
            mediumUsage => mediumUsage.usageType === 'Billing',
          ),
        )?.postalAddress;
      },
      civilInformation() {
        if (!this.user.individual) return undefined;

        return this.user.individual.individualName;
      },
      emailContact(): Maybe<CustomerEmailContactFragment> | undefined {
        return this.user.contactMediums?.contactMedium?.find(
          contactMedium => contactMedium.emailContact?.isPrimary,
        )?.emailContact;
      },
      isAccessTokenExpired() {
        if (!this._accessTokenExpiration) return true;

        const now = Math.round(new Date().getTime() / 1000);
        return now >= this._accessTokenExpiration;
      },
      isLoyalUser() {
        return this.user?.individual?.isLoyaltyMember || false;
      },
      isRefreshTokenLoading() {
        return (
          this.refreshTokenPromise.loading &&
          new Date().getTime() < this.refreshTokenPromise.expiredAt
        );
      },
      loyaltyCardCode() {
        return this.activeLoyaltyCard?.cardProduct?.cardCodeTARS ?? '';
      },
      loyaltyCardLabel() {
        return this.activeLoyaltyCard?.cardProduct?.productLabel ?? '';
      },
      loyaltyCardNumber() {
        return this.activeLoyaltyCard?.cardNumber ?? '';
      },
      loyaltyStatus() {
        return this.activeLoyaltyCard?.cardProduct?.productTier ?? '';
      },
      phoneNumber(): Maybe<CustomerPhoneNumberFragment> | undefined {
        return this.user.contactMediums?.contactMedium?.find(contactMedium =>
          contactMedium.mediumUsages?.mediumUsage?.some(
            mediumUsage => mediumUsage.usageType === 'Cell',
          ),
        )?.telephoneNumber;
      },
      primaryAddress(): Maybe<CustomerPostalAddressFragment> | undefined {
        return this.user.contactMediums?.contactMedium?.find(
          contactMedium => contactMedium.postalAddress?.isPrimary,
        )?.postalAddress;
      },
      isLogged() {
        return Boolean(this.user?.individual);
      },
      preferentialCode(): string | null {
        this._decodedAccessToken = this._accessToken
          ? decodeJwt(this._accessToken)
          : null;
        const identificationObject = this._decodedAccessToken
          ?.identification as Record<string, unknown> & {
          promotional?: { preferentialCode: string };
        };
        return identificationObject?.promotional?.preferentialCode as
          | string
          | null;
      },
    },
    state: () => ({
      _accessToken: null,
      _accessTokenExpiration: null,
      _decodedAccessToken: null,
      hasLoadedUser: false,
      refreshTokenPromise: {
        loading: false,
        expiredAt: 0,
        delay: 3000,
      },
      user: {},
    }),
  },
);
