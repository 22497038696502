<template>
  <span
    class="AiExternalIcon"
    :class="[`AiExternalIcon--${iconPosition}`]"
    aria-hidden="true">
    <svg class="AiExternalIcon-icon" :data-src="url" fill="currentColor" />
  </span>
</template>

<script setup lang="ts">
import 'external-svg-loader';

interface AiExternalIconProps {
  url: string;
  size?: number;
  iconPosition?: 'top' | 'center' | 'end';
}

const props = withDefaults(defineProps<AiExternalIconProps>(), {
  iconPosition: 'center',
  size: 24,
});

const iconSize = computed(() => `${props.size / 10}rem`);
</script>

<style lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/mq';

.AiExternalIcon-icon {
  vertical-align: middle;
  height: auto;
  width: 100%;
}

.AiExternalIcon {
  display: flex;
  justify-content: center;
  padding: constants.$inner-00;
  width: v-bind(iconSize);
  height: v-bind(iconSize);

  &--top {
    align-items: flex-start;
  }

  &--end {
    align-items: flex-end;
  }

  &--center {
    align-items: center;
  }
}
</style>
