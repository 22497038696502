import { BASKET_AUTHENTICATION_STATUS } from '../constants';

import { SESSION_STORAGE_KEYS } from '~~/core';
import { useUserStore } from '~~/domains/user';

const triggered = ref<boolean>(false);

export function useBookingAuthenticationGuard() {
  const userStore = useUserStore();

  const currentStatus = computed(() =>
    userStore.isLogged
      ? BASKET_AUTHENTICATION_STATUS.Logged
      : BASKET_AUTHENTICATION_STATUS.Anonymous,
  );

  function setAuthGuardStatus() {
    const guardStatus = currentStatus.value;

    window.sessionStorage.setItem(
      SESSION_STORAGE_KEYS.BASKET_AUTH_CREATION,
      guardStatus,
    );
  }

  function triggerAuthGuardModal() {
    triggered.value = true;
  }

  function clearAuthGuardStatus() {
    window.sessionStorage.removeItem(SESSION_STORAGE_KEYS.BASKET_AUTH_CREATION);
  }

  const authStatusMismatch = computed(() => {
    const previousStatus =
      window.sessionStorage.getItem(
        SESSION_STORAGE_KEYS.BASKET_AUTH_CREATION,
      ) || undefined;

    return Boolean(previousStatus) && previousStatus !== currentStatus.value;
  });

  return {
    authStatusMismatch,
    setAuthGuardStatus,
    triggerAuthGuardModal,
    isModalTriggered: triggered,
    clearAuthGuardStatus,
  };
}
