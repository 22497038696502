<template>
  <span
    class="AiIcon"
    :class="[
      `AiIcon--${name}`,
      { 'AiIcon--no-padding': noPadding },
      { 'AiIcon--block': block },
    ]">
    <svg
      class="AiIcon-svg"
      :class="{ [`AiIcon-svg--${fill}`]: Boolean(fill) }"
      aria-hidden="true">
      <use :href="asset" />
    </svg>
    <span v-if="description" class="sr-only">{{ description }}</span>
  </span>
</template>

<script setup lang="ts">
import type { TypoColor } from '../AiTypo/types';

import type { GenericIconName, IconNameSpace } from './types';

export interface SizeProp {
  height: number;
  width: number;
}

interface IconProps {
  block?: boolean;
  description?: string;
  fill?: TypoColor;
  name: GenericIconName;
  namespace?: IconNameSpace;
  noPadding?: boolean;
  size?: SizeProp | number;
  customClass?: string;
}

const props = withDefaults(defineProps<IconProps>(), {
  customClass: undefined,
  description: undefined,
  fill: undefined,
  namespace: 'global',
  noPadding: false,
  size: 24,
});

const asset = computed<string>(() => {
  let url = '/assets/';

  switch (props.namespace) {
    case 'amenities':
      url += 'amenities-sprites.svg';
      break;

    case 'payment':
      url += 'payment-sprites.svg';
      break;

    case 'global':
    default:
      url += 'global-sprites.svg';
      break;
  }

  return `${url}#${props.name}`;
});

const iconHeight = computed(() => {
  if (typeof props.size === 'number') {
    return `${props.size / 10}rem`;
  }

  return `${props.size.height / 10}rem`;
});

const iconWidth = computed(() => {
  if (typeof props.size === 'number') {
    return `${props.size / 10}rem`;
  }

  return `${props.size.width / 10}rem`;
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';

.AiIcon {
  font-size: 0;
}

.AiIcon-svg {
  display: inline-block;
  width: v-bind(iconWidth);
  height: v-bind(iconHeight);

  @include mixins.allColorColorsVariant();
}

.AiIcon--block {
  display: block;
}

.AiIcon--noPadding {
  padding: 0;
}
</style>
