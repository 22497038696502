import { useSessionStorage } from '@vueuse/core';
import type { RouteLocationNormalized } from 'vue-router';

const MAX_HISTORY = 30;

export function useAppHistory() {
  const router = useRouter();

  const history = useSessionStorage(
    'app-history',
    [] as {
      toUrl: string;
      toName: string | undefined;
      query: object;
      params: object;
    }[],
    { deep: true },
  );

  router.afterEach(
    (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      const historyEntry = {
        toUrl: to.path,
        toName: to.name?.toString(),
        query: to.query,
        params: to.params,
      };
      history.value.unshift(historyEntry);

      if (history.value.length > MAX_HISTORY) {
        history.value = history.value.slice(0, MAX_HISTORY);
      }
    },
  );

  function getLatestRouteMatching(partialRouteName: string) {
    if (!history.value.length) return;

    const item = history.value.find(({ toName }) =>
      toName?.includes(partialRouteName),
    );

    if (item) {
      try {
        return router.resolve({
          name: item.toName,
          path: item.toUrl,
          params: item.params,
          query: item.query,
        });
      } catch (error) {
        console.error('Erreur :', error);
      }
    }

    return {};
  }

  return {
    getLatestRouteMatching,
  };
}
