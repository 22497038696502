export enum BookingRateFilter {
  Loyalty = 'LOYALTY',
  OnlyOn = 'ONLY_ON',
  SpecialOffers = 'SPECIAL_OFFERS',
  Standard = 'STANDARD',
  Preferred = 'PREFERRED',
  Full = 'FULL',
}

export enum BookingRoomFilter {
  All = 'All',
  Rooms = 'Rooms',
  Suites = 'Suites',
  Villas = 'Villas',
  Penthouse = 'Penthouse',
}

export enum PaymentMode {
  AmericanExpress = 'AX',
  BancContact = 'banc-contact',
  BlueCard = 'blue-card',
  ChinaUnionPay = 'china-union-pay',
  DinersClub = 'DC',
  Elo = 'elo',
  JBC = 'jbc',
  Maestro = 'maestro',
  Mastercard = 'CA',
  PostFinance = 'post-finance',
  RuPay = 'ru-pay',
  Visa = 'VI',
}

export enum BasketPaymentTypeWithGuarantee {
  NoWarranty = 'NO_WARRANTY',
  OptionalGuarantee = 'OPTIONAL_CREDIT_CARD',
  MandatoryGuarantee = 'MANDATORY_CREDIT_CARD',
  PrePayableManual = 'PREPAYABLE_MANUAL',
  PrePayableAutomatic = 'PREPAYABLE_AUTOMATIC',
}
