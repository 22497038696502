export const AUTHENTICATION_COOKIE_NAMES = [
  'id_token',
  'access_token',
  'refresh_token',
  'pi_sri',
];

export const hasIdentificationCookies = (
  requestEvent?: ReturnType<typeof useRequestEvent> | null,
) => {
  const cookies = parseRawCookie(requestEvent);

  return Object.keys(cookies).every(cookie =>
    AUTHENTICATION_COOKIE_NAMES.includes(cookie),
  );
};

export const parseRawCookie = (
  requestEvent: ReturnType<typeof useRequestEvent> | null,
): Record<string, string> => {
  const rawCookie = getRawCookie(requestEvent);

  return rawCookie
    .split('; ')
    .reduce<Record<string, string>>((accumulator, item) => {
      const [key, ...value] = item.split('=');
      accumulator[key] = value.join('=');

      return accumulator;
    }, {});
};

export const getRawCookie = (
  requestEvent: ReturnType<typeof useRequestEvent> | null,
) => {
  let rawCookie;
  if (process.server) {
    rawCookie = requestEvent?.node?.req?.headers?.cookie || '';
  } else {
    rawCookie = document.cookie;
  }

  return rawCookie;
};
