<template>
  <ai-popin
    v-model="opened"
    drawer-popin
    :content-align="contentAlign"
    full
    @overlay-click="props.noClickOutside ? undefined : (opened = false)"
    @popin-close="closed">
    <ai-transition
      :name="`slide-${contentAlign}`"
      :duration="500"
      @after-leave="onAfterLeave">
      <div v-if="drawerOpened" class="AiDrawer">
        <!-- Header -->
        <div
          v-if="title || $slots.header"
          class="AiDrawer-header"
          :class="{ 'AiDrawer-header--large': largeHeader }"
          @click.stop>
          <slot name="header" :on-close="() => (opened = false)">
            <slot v-if="showHeaderLeft" name="header-left">
              <ai-button
                class="AiMobileDrawerForm-headerLeft"
                variant="tertiary"
                slim
                :aria-label="$t('accessibility.description.leftButton')"
                @click="$emit('update:page', undefined)">
                <ai-icon name="arrow-left" :size="24" />
              </ai-button>
            </slot>

            <ai-typo
              class="AiDrawer-headerTitle"
              as="span"
              variant="button-regular"
              color="gold-900">
              {{ title }}
            </ai-typo>

            <ai-button
              class="AiDrawer-headerClose"
              variant="tertiary"
              slim
              :aria-label="$t('ux.molecules.modal.close.label')"
              @click="closed">
              <ai-icon
                class="AiDrawer-headerCloseIcon"
                name="close"
                :size="largeHeader ? 24 : 16" />
            </ai-button>
          </slot>
        </div>

        <!-- Body -->
        <div class="AiDrawer-body" @click.stop>
          <slot />
        </div>
      </div>
    </ai-transition>
  </ai-popin>
</template>

<script setup lang="ts">
import { useLayoutStore } from '../../layout.store';
import AiPopin from '../AiPopin/AiPopin.vue';

type Props = {
  modelValue: boolean;
  title?: string;
  overflow?: boolean;
  contentAlign?: 'left' | 'center' | 'right' | 'top';
  noClickOutside?: boolean;
  largeHeader?: boolean;
  lockBackgroundScroll?: boolean;
  showHeaderLeft?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: boolean): void;
  (event: 'update:page', value: string | undefined): void;
  (event: 'popin-close'): void;
};

const props = withDefaults(defineProps<Props>(), {
  contentAlign: 'right',
  overflow: false,
  title: undefined,
  lockBackgroundScroll: true,
});

const layoutStore = useLayoutStore();

const emits = defineEmits<Emits>();

const drawerOpened = ref(props.modelValue);

const opened = computed<boolean>({
  get() {
    return props.modelValue;
  },
  set(open) {
    if (!open) {
      drawerOpened.value = false;
    } else {
      drawerOpened.value = open;
      emits('update:modelValue', open);
    }
  },
});

const closed = () => {
  opened.value = false;

  emits('popin-close');
};

watch(opened, _opened => {
  drawerOpened.value = _opened;
  layoutStore.setScrollLocked(props.lockBackgroundScroll && _opened);
});

const onAfterLeave = () => {
  emits('update:modelValue', false);
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/mixins';

.AiDrawer {
  position: relative;
  height: -webkit-calc(
    var(--vh, 1vh) * 100
  ); /* Fallback for browsers Samsung */
  height: 100dvh; /* https://caniuse.com/mdn-css_types_length_viewport_percentage_units_dynamic */

  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  background-color: colors.$white;
  display: flex;
  flex-direction: column;

  @media (mq.$from-large) {
    width: 50%;
  }

  &--overflow {
    overflow: scroll;
  }
}

.AiDrawer::-webkit-scrollbar {
  width: constants.$inner-01;
  background-color: transparent;
}
.AiDrawer::-webkit-scrollbar-thumb {
  background: colors.$gold-700;
}
.AiDrawer-header {
  position: sticky;
  z-index: 10;
  background-color: colors.$white;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  padding-top: constants.$padding-01;
  padding-bottom: constants.$padding-01;

  border-bottom: 1px solid colors.$gold-200;

  &--large {
    padding-top: constants.$padding-02;
    padding-bottom: constants.$padding-02;
  }

  @media (mq.$from-medium) {
    padding-top: constants.$padding-02;
    padding-bottom: constants.$padding-02;
  }
}

.AiMobileDrawerForm-headerLeft {
  position: absolute;
  top: calc(50% - 12px); // 12px for half button height
  left: constants.$padding-01;
}

.AiDrawer-headerTitle {
  text-transform: uppercase;

  padding-right: calc(
    constants.$padding-01 + 24px
  ); // Button width + padding (16 + 8)
  padding-left: constants.$padding-01;
}

.AiDrawer-headerClose {
  position: absolute;
  right: constants.$padding-01;
  top: calc(50% - 12px); // 12px for half button height

  @media (mq.$from-medium) {
    right: constants.$padding-02;
  }
}

.AiDrawer-headerCloseIcon {
  fill: colors.$gold-900;
}

.AiDrawer-body {
  position: relative;
  flex-grow: 1;
}
</style>
