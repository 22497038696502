<template>
  <transition
    :name="name"
    :appear="appear"
    @before-enter="$emit('before-enter')"
    @enter="$emit('enter')"
    @after-enter="$emit('after-enter')"
    @enter-cancelled="$emit('enter-cancelled')"
    @before-leave="$emit('before-leave')"
    @leave="$emit('leave')"
    @after-leave="$emit('after-leave')"
    @leave-cancelled="$emit('leave-cancelled')">
    <slot />
  </transition>
</template>

<script setup lang="ts">
type Props = {
  appear?: boolean;
  duration?: number;
  name: 'slide-right' | 'slide-top' | string;
};

const props = withDefaults(defineProps<Props>(), {
  appear: true,
  duration: 300,
});

type Emits = {
  (event: 'before-enter'): void;
  (event: 'enter'): void;
  (event: 'after-enter'): void;
  (event: 'enter-cancelled'): void;
  (event: 'before-leave'): void;
  (event: 'leave'): void;
  (event: 'after-leave'): void;
  (event: 'leave-cancelled'): void;
};

defineEmits<Emits>();

const durationInMs = computed(() => `${props.duration}ms`);
</script>

<style scoped lang="scss">
/*
  Slide right
*/
.slide-right-leave-active,
.slide-right-enter-active {
  transition: transform ease-out v-bind(durationInMs);
  position: absolute;
  inset: 0;
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-right-enter-to,
.slide-right-leave-from {
  transform: translateX(0);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

/*
  Slide top
*/
.slide-top-leave-active,
.slide-top-enter-active {
  transition: transform ease-out v-bind(durationInMs);
}

.slide-top-enter-from {
  transform: translateY(100%);
}

.slide-top-enter-to,
.slide-top-leave-from {
  transform: translateY(0);
}

.slide-top-leave-to {
  transform: translateY(100%);
}
</style>
