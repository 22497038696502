import { useApolloClient } from '@vue/apollo-composable';

import { useReferentialStore } from '../store';

import type { Language } from '~~/domains/graphql';

export async function usePreloadLanguages() {
  const store = useReferentialStore();
  const { client } = useApolloClient();

  await store.fetchLanguages(client);
}

export function useLanguages() {
  const store = useReferentialStore();
  const { client } = useApolloClient();

  if (!store._languages?.length) {
    store.fetchLanguages(client);
  }

  return computed(() => store.languages ?? []);
}

export function useLanguagesMap() {
  const languages = useLanguages();

  return computed(() =>
    languages.value.reduce(
      (acc, language) => ({ ...acc, [language.code]: language }),
      {} as Record<Language['code'] | string, Language>,
    ),
  );
}
