import { useApolloClient } from '@vue/apollo-composable';

import { useReferentialStore } from '../store';

import type { Currency } from '~~/domains/graphql';

export async function usePreloadCurrencies() {
  const store = useReferentialStore();
  const { client } = useApolloClient();

  if (!store._currencies?.length) {
    await store.fetchCurrencies(client);
  }
}

export function useCurrencies() {
  const store = useReferentialStore();
  const { client } = useApolloClient();

  if (!store._currencies?.length) {
    store.fetchCurrencies(client);
  }

  return computed(() => store.currencies ?? []);
}

export function useCurrenciesMap() {
  const currencies = useCurrencies();

  return computed(() =>
    currencies.value.reduce(
      (acc, currency) => ({ ...acc, [currency.code]: currency }),
      {} as Record<Currency['code'] | string, Currency>,
    ),
  );
}
