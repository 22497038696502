import { useRefreshPricesDuration } from './useRefreshPricesDuration';

type ShouldRedirectCallback = { pre?: () => unknown; post?: () => unknown };

type BookingPriceStaleTimerHook = {
  refreshStalePriceTimer(): void;
  stalePriceTimerReached: Ref<boolean>;
  onShouldRedirect: (callbacks?: ShouldRedirectCallback) => void;
};

const timerId = ref<NodeJS.Timeout>();
const stalePriceTimerReached = ref<boolean>(false);

export function useBookingPriceStaleTimer(): BookingPriceStaleTimerHook {
  const timerDuration = useRefreshPricesDuration();

  const clearTimer = () => {
    if (timerId.value) clearTimeout(timerId.value);

    timerId.value = undefined;
  };

  const refreshStalePriceTimer = () => {
    clearTimer();
    timerId.value = setTimeout(onTimerReached, timerDuration.inMilliseconds);
  };

  const onTimerReached = () => {
    clearTimer();
    stalePriceTimerReached.value = true;
  };

  const onShouldRedirect = (callbacks?: ShouldRedirectCallback) => {
    if (typeof callbacks?.pre === 'function') {
      callbacks.pre();
    }

    refreshStalePriceTimer();

    nextTick(() => {
      return window.location.reload();
    });

    if (typeof callbacks?.post === 'function') {
      callbacks.post();
    }
  };

  onBeforeUnmount(() => {
    clearTimer();
  });

  return { refreshStalePriceTimer, stalePriceTimerReached, onShouldRedirect };
}
