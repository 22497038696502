export default defineNuxtPlugin(app => {
  if (process.server) return;

  const config = useRuntimeConfig();
  const router = useRouter();
  const localePath = useLocalePath();

  // @ts-ignore-next-line
  app.$i18n.onLanguageSwitched = (_: string, newLocale: string) => {
    const route = router.currentRoute.value;

    const name = route.name as string;

    const nextLocalisedPath = localePath(
      {
        name: name.slice(0, -5),
        query: route.query,
      },
      encodeURIComponent(newLocale),
    );

    window.location.href = config.public.baseUrl + nextLocalisedPath;
  };
});
