import type { InMemoryCacheConfig } from '@apollo/client/cache';

import type { StrictTypedTypePolicies } from './helpers';

const typePolicies: StrictTypedTypePolicies = {
  GlobalConfiguration: {
    merge: true,
  },
};

export const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies,
};
