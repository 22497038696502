import type { SupportedBrands } from '~~/core';
import { DEFAULT_BRAND } from '~~/core';
import type { LogoName } from '~~/domains/ux/atoms/AiIcon/types';

type BrandNameParam = string | null | undefined;

const allBrands: SupportedBrands[] = [
  'AAI',
  'ALI',
  'FAI',
  'HYD',
  'RIX',
  'SLS',
  'SO',
  'SWI',
];

const BrandsLogoMapping: Record<SupportedBrands, LogoName> = {
  AAI: 'accor_all_inclusive',
  ALI: 'accorLiveLimitless',
  FAI: 'fairmont',
  HYD: 'hyde',
  RIX: 'rixos',
  SLS: 'sls',
  SO: 'sohotels',
  SWI: 'swisshotel',
};

const BrandsNameMapping: Record<SupportedBrands, string> = {
  AAI: 'Accor All Inclusive',
  ALI: 'Accor Live Limitless',
  FAI: 'Fairmont',
  HYD: 'Hyde',
  RIX: 'Rixos',
  SLS: 'SLS',
  SO: 'SO/',
  SWI: 'Swiss Hotel',
};

export const isSupportedBrand = (
  brand: BrandNameParam,
): brand is SupportedBrands => {
  return Boolean(brand) && allBrands.includes(brand as SupportedBrands);
};

export const getBrandLogoName = (brand: BrandNameParam): LogoName => {
  return isSupportedBrand(brand)
    ? BrandsLogoMapping[brand]
    : BrandsLogoMapping[DEFAULT_BRAND];
};

export const getBrandName = (brand: BrandNameParam): string => {
  return isSupportedBrand(brand)
    ? BrandsNameMapping[brand]
    : BrandsNameMapping[DEFAULT_BRAND];
};
