import { ENABLED_LANGUAGES, SupportedLanguages } from '~~/core';

export default defineNuxtRouteMiddleware(to => {
  const bannedLanguages = Object.values(SupportedLanguages).filter(
    language => !ENABLED_LANGUAGES.includes(language),
  );

  const isBannedTo = bannedLanguages.some(language =>
    to.fullPath.startsWith(`/${language}`),
  );

  if (!isBannedTo) return;

  const isDraft = to.query.draft === 'true';

  if (isDraft) return;

  return abortNavigation({ fatal: true, statusCode: 404 });
});
