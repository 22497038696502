import validate from "/usr/src/app/packages/all-inclusive-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import booking_45global from "/usr/src/app/packages/all-inclusive-app/middleware/booking.global.ts";
import content_45preview_45global from "/usr/src/app/packages/all-inclusive-app/middleware/content-preview.global.ts";
import localization_45global from "/usr/src/app/packages/all-inclusive-app/middleware/localization.global.ts";
import maintenance_45global from "/usr/src/app/packages/all-inclusive-app/middleware/maintenance.global.ts";
import redirect_45global from "/usr/src/app/packages/all-inclusive-app/middleware/redirect.global.ts";
import refresh_45token_45global from "/usr/src/app/packages/all-inclusive-app/middleware/refresh-token.global.ts";
import trailing_45slash_45global from "/usr/src/app/packages/all-inclusive-app/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/usr/src/app/packages/all-inclusive-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  booking_45global,
  content_45preview_45global,
  localization_45global,
  maintenance_45global,
  redirect_45global,
  refresh_45token_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "query-params-cleaners": () => import("/usr/src/app/packages/all-inclusive-app/middleware/query-params-cleaners.ts")
}