<template>
  <div
    v-click-away="close"
    class="AiHeaderMenu"
    @keydown.esc="toggleAiHeaderMenu">
    <ai-button
      ref="toggleButton"
      variant="tertiary"
      :aria-controls="controls"
      :aria-expanded="open"
      @click="toggleAiHeaderMenu">
      <div
        v-if="$slots.left"
        class="AiHeaderMenu-iconContainer AiHeaderMenu-iconContainer--left">
        <slot name="left" />
      </div>

      <ai-typo
        class="AiHeaderMenu-label"
        :class="{ 'AiHeaderMenu-label--inverse': inverse }"
        as="span"
        variant="overline-01">
        {{ label }}
      </ai-typo>

      <div
        class="AiHeaderMenu-iconContainer AiHeaderMenu-iconContainer--right"
        :class="{ 'AiHeaderMenu-iconContainer--open': open }">
        <slot name="right">
          <ai-icon
            name="chevron-down"
            :size="16"
            class="AiHeaderMenu-icon"
            :class="{ 'AiHeaderMenu-icon--inverse': inverse }" />
        </slot>
      </div>
    </ai-button>
    <div v-if="open" @click.stop>
      <slot name="inner" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { directive as vClickAway } from '~~/directives/click-away';

type Props = {
  controls: string;
  label: string;
  inverse?: boolean;
  open?: boolean;
};

type Emits = {
  (event: 'click'): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const toggleButton = ref();

const toggleAiHeaderMenu = () => {
  if (props.open === true) {
    toggleButton.value?.$el.focus();
  }
  emits('click');
};

const close = () => {
  if (!props.open) {
    return;
  }

  emits('click');
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';

.AiHeaderMenu {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.AiHeaderMenu-label {
  text-transform: uppercase;
  color: colors.$stratos-900;
  transition: all 300ms;

  &--inverse {
    color: colors.$white;
  }
}

.AiHeaderMenu-iconContainer {
  font-size: 0;
  transition: all 300ms;

  &--right {
    margin-left: constants.$inner-02;
  }

  &--left {
    margin-right: constants.$inner-02;
  }

  &--open {
    transform: rotate(180deg);
  }
}

.AiHeaderMenu-icon {
  color: colors.$gold-700;
  transition: all 300ms;

  &--inverse {
    color: colors.$gold-300;
  }
}
</style>
