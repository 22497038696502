import { ApolloClient } from '@apollo/client/core';
import { defineStore } from 'pinia';

import type { Currency, Language } from '../graphql';
import { CurrenciesDocument, LanguagesDocument } from '../graphql';

type State = {
  _currencies: Currency[];
  _languages: Language[];
};

type Getters = {
  currencies(): Currency[];
  languages(): Language[];
};

type Actions = {
  fetchCurrencies(client: ApolloClient<object>): Promise<Currency[]>;
  fetchLanguages(client: ApolloClient<object>): Promise<Language[]>;
};

export const useReferentialStore = defineStore<
  'referential',
  State,
  Getters,
  Actions
>('referential', {
  actions: {
    async fetchCurrencies(client) {
      const { data } = await client.query({ query: CurrenciesDocument });

      this._currencies = data.currencies as Currency[];

      return this._currencies;
    },
    async fetchLanguages(client) {
      const { data } = await client.query({ query: LanguagesDocument });

      this._languages = data.languages as Language[];

      return this._languages;
    },
  },
  getters: {
    currencies() {
      return this._currencies;
    },
    languages() {
      return this._languages;
    },
  },
  state() {
    return {
      _currencies: [],
      _languages: [],
    };
  },
});
