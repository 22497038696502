export * from './analytics.helper';
export * from './backend-url.helper';
export * from './booking.helper';
export * from './brand.helper';
export * from './cookie.helper';
export * from './currency.helper';
export * from './date.helper';
export * from './gtm.helper';
export * from './jsonld.helper';
export * from './label.helper';
export * from './modal.helper';
export * from './redirect.helper';
export * from './redirect.interface';
export * from './route.helper';
export * from './taxesPrices.helper';
export * from './url.helper';

function focusTrapEvent(event: KeyboardEvent, el: HTMLElement | undefined) {
  const firstFocusableElement = el?.querySelectorAll(
    focusableElements,
  )[0] as HTMLElement;
  const focusableContent = el?.querySelectorAll(focusableElements) ?? [];
  const lastFocusableElement = focusableContent[
    focusableContent.length - 1
  ] as HTMLElement;
  const isTabPressed = event.key === 'Tab';
  if (!isTabPressed) {
    return;
  }

  if (event.shiftKey) {
    // if shift key pressed for shift + tab combination
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      event.preventDefault();
    }
    // if tab key is pressed
  } else if (document.activeElement === lastFocusableElement) {
    firstFocusableElement?.focus();
    event.preventDefault();
  }
}

/**
 *
 * @param el
 */
export function addTrapFocusToElement(el: HTMLElement | undefined): void {
  el?.addEventListener('keydown', (event: KeyboardEvent) =>
    focusTrapEvent(event, el),
  );
}

// We want to keep the order of keys based on their value, not alphabetic one

export const breakpoints = {
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

/**
 * @returns string
 * @param str
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

/**
 *
 * @param el
 * @returns boolean
 */
export function isElementInViewport(el: HTMLElement): boolean {
  if (!el) return false;
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
 * Check if it's an external url
 *
 * @param context
 * @param url
 * @returns boolean
 *
 */
export function isExternalURL(
  context: Record<string, unknown>,
  url: string,
): boolean {
  return isUrl(url) ? new URL(url).origin !== context?.req : false;
}

/**
 *
 * @param string
 */
function isUrl(string: string) {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
}

/**
 *
 * @param time
 */
// Parses an RFC 3339 compliant time-string into a Date.
// It does this by combining the current date with the time-string
// to create a new Date instance.
//
// Example:
// Suppose the current date is 2016-01-01, then
// parseTime('11:00:12Z') parses to a Date corresponding to
// 2016-01-01T11:00:12Z.
export function parseTime(time: string): Date {
  const currentDateString = new Date().toISOString();
  return new Date(
    currentDateString.substring(0, currentDateString.indexOf('T') + 1) + time,
  );
}

/**
 *
 * @param el
 */
export function removeTrapFocusToElement(el: HTMLElement | undefined): void {
  el?.removeEventListener('keydown', (event: KeyboardEvent) =>
    focusTrapEvent(event, el),
  );
}

/**
 * @param hash
 * @param parent
 */
export function scrollTo(hash: string, parent: HTMLElement | Document): void {
  const elementToScrollInto = parent?.querySelector(hash) as HTMLElement;

  if (elementToScrollInto) {
    const pos = elementToScrollInto.style.position;
    const top = elementToScrollInto.style.top;

    elementToScrollInto.style.position = 'relative';
    elementToScrollInto.style.top = '-96px';

    elementToScrollInto.scrollIntoView({ behavior: 'smooth', block: 'start' });

    elementToScrollInto.style.top = top;
    elementToScrollInto.style.position = pos;
  }
}

/**
 * @returns string
 * @param str
 */
export function slugify(str: string): string {
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
}

/**
 * @returns string[][]
 * @param array
 */
export function splitArray<T>(array: T[]): T[][] {
  const half = Math.ceil(array.length / 2);
  return [array.slice(0, half), array.slice(half)];
}

/**
 * @returns string
 * @param str
 */
export function toCamelCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
}

/**
 * @param { Promise } promise
 * @param { Object= } errorExt - Additional Information you can pass to the err object
 * @return { Promise }
 */
export function to<T, U = Error>(
  promise: Promise<T>,
  errorExt?: object,
): Promise<[U, undefined] | [null, T]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, undefined]>((err: U) => {
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt);
        return [parsedError, undefined];
      }

      return [err, undefined];
    });
}
