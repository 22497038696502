import { setContext } from '@apollo/client/link/context';

import { useUserStore } from '~/domains/user';

export const refreshTokenLink = setContext(async (_, { headers }) => {
  if (process.server) {
    return { headers };
  }

  const userStore = useUserStore();

  // Wait for the concurrent refresh promise to finish or expire
  while (userStore.isRefreshTokenLoading) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }

  if (!userStore.accessToken) {
    return { headers };
  }

  if (userStore.isAccessTokenExpired) {
    await userStore.refreshAccessToken();

    if (!userStore.accessToken) {
      // Refresh Token failed, unhandled case, reload the page to discard the current auth state
      window.location.reload();
    }

    return {
      headers,
    };
  }

  return {
    headers,
  };
});
