import { eventCustom } from '~/helpers';

interface CustomError extends Error {
  statusCode?: number;
  response: { status?: string };
  cause?: { statusCode?: string };
}

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.config.errorHandler = error => {
    const typedError = error as CustomError;

    const statusCode = typedError.response ? typedError.response.status : null;
    eventCustom('Generic Event', {
      event_name: 'error_tracking',
      error_tracking: {
        error_message: typedError.message,
        error_code: statusCode,
        error_type: '',
      },
    });
  };
  nuxtApp.hook('app:error', (error: CustomError) => {
    const statusCode = error.cause ? error.cause.statusCode : null;
    eventCustom('Generic Event', {
      event_name: 'error_tracking',
      error_tracking: {
        error_message: error.message,
        error_code: statusCode,
        error_type: '',
      },
    });
  });
});
