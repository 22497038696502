import PinoLogger from 'pino';

export default defineNuxtPlugin(nuxt => {
  const logger = PinoLogger({ level: 'trace' });

  if (import.meta.env.SSR) {
    nuxt.vueApp.config.warnHandler = message => {
      logger.warn(null, message);
    };
    nuxt.vueApp.config.errorHandler = error => {
      logger.error(error);
    };
  }
});
