<template>
  <a
    v-if="isExternalLink || forceAnchor"
    :aria-current-value="ariaCurrentValue"
    :class="{
      'AiLink--disabled': disabled,
      'AiLink--undecorated': undecorated,
      'AiLink--button': button,
    }"
    :href
    :rel="target === '_blank' ? 'noopener noreferrer' : ''"
    :target="target"
    class="AiLink">
    <span v-if="iconLeft" class="AiLink-icon AiLink-icon--left">
      <ai-icon :fill="color" :name="iconLeft" :size="size" />
    </span>

    <ai-typo
      :as="typoHtmlTagElement"
      :class="{ 'AiLink--undecorated': undecorated }"
      :color="color"
      :itemprop="titleItemprop"
      :variant="typoVariant">
      {{ text }}
      <slot />
      <ai-typo v-if="target === '_blank'" as="span" class="sr-only">
        {{ $t('accessibility.description.linkNewWindow', { title: title }) }}
      </ai-typo>
    </ai-typo>

    <span v-if="iconRight" class="AiLink-icon AiLink-icon--right">
      <ai-icon :fill="color" :name="iconRight" :size="size" />
    </span>
  </a>

  <nuxt-link
    v-else
    :aria-current-value="ariaCurrentValue"
    :class="{
      'AiLink--disabled': disabled,
      'AiLink--undecorated': undecorated,
      'AiLink--button': button,
    }"
    :external="external"
    :target="target"
    :to="href"
    class="AiLink">
    <span v-if="iconLeft" class="AiLink-icon AiLink-icon--left">
      <ai-icon :fill="color" :name="iconLeft" :size="size" />
    </span>

    <ai-typo
      :as="typoHtmlTagElement"
      :class="{ 'AiLink--undecorated': undecorated }"
      :color="color"
      :itemprop="titleItemprop"
      :variant="typoVariant">
      {{ text }}
      <slot />
      <ai-typo v-if="target === '_blank'" as="span" class="sr-only">
        {{ $t('accessibility.description.linkNewWindow', { title: title }) }}
      </ai-typo>
    </ai-typo>

    <span v-if="iconRight" class="AiLink-icon AiLink-icon--right">
      <ai-icon :fill="color" :name="iconRight" :size="size" />
    </span>
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import type { IconName } from '../AiIcon/types';
import type { AiTypoVariant, TypoColor } from '../AiTypo/types';

export interface Props {
  ariaCurrentValue?: string;
  button?: boolean;
  color?: TypoColor;
  disabled?: boolean;
  external?: boolean;
  forceAnchor?: boolean;
  iconLeft?: IconName | null;
  iconRight?: IconName | null;
  size?: number;
  target?: '_blank' | '_self' | '_parent' | '_top';
  text?: string;
  title?: string;
  titleItemprop?: string;
  to?: RouteLocationRaw;
  typoHtmlTagElement?: keyof HTMLElementTagNameMap;
  typoVariant?: AiTypoVariant;
  undecorated?: boolean;
  url?: string;
}

const props = withDefaults(defineProps<Props>(), {
  ariaCurrentValue: undefined,
  button: false,
  color: 'stratos-900',
  iconLeft: null,
  iconRight: null,
  size: 24,
  target: '_self',
  text: undefined,
  title: undefined,
  titleItemprop: undefined,
  to: undefined,
  typoHtmlTagElement: 'span',
  typoVariant: 'button-bold-underline',
  undecorated: false,
  url: undefined,
});

const localePath = useLocalePath();

const to = computed<RouteLocationRaw | string | undefined>(() => {
  if (props.disabled) return;

  if (props.to) {
    return props.to;
  }

  return props.url;
});

const href = computed<string | undefined>(() => {
  if (!to.value) return;

  if (typeof to.value === 'string') {
    return to.value;
  }

  return localePath(to.value);
});

const isExternalLink = computed(() => {
  if (props.to) {
    return false;
  }

  return Boolean(href.value && href.value.startsWith('http'));
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';

.AiLink {
  text-decoration-color: colors.$white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &--underline {
    text-decoration: underline;
  }

  &--undecorated {
    text-decoration: none;
  }

  &--button {
    text-transform: uppercase;
    text-decoration: none;
    color: colors.$gold-900;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
}

.AiLink-icon {
  display: flex;
  margin-right: 7px;

  &--left {
    margin-right: constants.$inner-02;
  }

  &--right {
    margin-left: constants.$inner-02;
  }
}
</style>
