
// @ts-nocheck


export const localeCodes =  [
  "en",
  "ru",
  "tr",
  "de",
  "fr",
  "es",
  "xx"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/locales/en-US.json", load: () => import("../i18n/locales/en-US.json" /* webpackChunkName: "locale__usr_src_app_packages_all_inclusive_app_i18n_locales_en_US_json" */), cache: true }],
  "ru": [{ key: "../i18n/locales/ru-RU.json", load: () => import("../i18n/locales/ru-RU.json" /* webpackChunkName: "locale__usr_src_app_packages_all_inclusive_app_i18n_locales_ru_RU_json" */), cache: true }],
  "tr": [{ key: "../i18n/locales/tr-TR.json", load: () => import("../i18n/locales/tr-TR.json" /* webpackChunkName: "locale__usr_src_app_packages_all_inclusive_app_i18n_locales_tr_TR_json" */), cache: true }],
  "de": [{ key: "../i18n/locales/de-DE.json", load: () => import("../i18n/locales/de-DE.json" /* webpackChunkName: "locale__usr_src_app_packages_all_inclusive_app_i18n_locales_de_DE_json" */), cache: true }],
  "fr": [{ key: "../i18n/locales/fr-FR.json", load: () => import("../i18n/locales/fr-FR.json" /* webpackChunkName: "locale__usr_src_app_packages_all_inclusive_app_i18n_locales_fr_FR_json" */), cache: true }],
  "es": [{ key: "../i18n/locales/es-ES.json", load: () => import("../i18n/locales/es-ES.json" /* webpackChunkName: "locale__usr_src_app_packages_all_inclusive_app_i18n_locales_es_ES_json" */), cache: true }],
  "xx": [{ key: "../i18n/locales/xx-XX.json", load: () => import("../i18n/locales/xx-XX.json" /* webpackChunkName: "locale__usr_src_app_packages_all_inclusive_app_i18n_locales_xx_XX_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "dir": "ltr",
      "iso": "en-US",
      "name": "English",
      "files": [
        "i18n/locales/en-US.json"
      ]
    },
    {
      "code": "ru",
      "dir": "ltr",
      "iso": "ru-RU",
      "name": "Russian",
      "files": [
        "i18n/locales/ru-RU.json"
      ]
    },
    {
      "code": "tr",
      "dir": "ltr",
      "iso": "tr-TR",
      "name": "Turc",
      "files": [
        "i18n/locales/tr-TR.json"
      ]
    },
    {
      "code": "de",
      "dir": "ltr",
      "iso": "de-DE",
      "name": "German",
      "files": [
        "i18n/locales/de-DE.json"
      ]
    },
    {
      "code": "fr",
      "dir": "ltr",
      "iso": "fr-FR",
      "name": "Français",
      "files": [
        "i18n/locales/fr-FR.json"
      ]
    },
    {
      "code": "es",
      "dir": "ltr",
      "iso": "es-ES",
      "name": "Spanish",
      "files": [
        "i18n/locales/es-ES.json"
      ]
    },
    {
      "code": "xx",
      "dir": "ltr",
      "iso": "xx-XX",
      "name": "Translations",
      "files": [
        "i18n/locales/xx-XX.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./i18n/locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "en",
    "redirectOn": "no prefix",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "dir": "ltr",
    "iso": "en-US",
    "name": "English",
    "files": [
      {
        "path": "i18n/locales/en-US.json"
      }
    ]
  },
  {
    "code": "ru",
    "dir": "ltr",
    "iso": "ru-RU",
    "name": "Russian",
    "files": [
      {
        "path": "i18n/locales/ru-RU.json"
      }
    ]
  },
  {
    "code": "tr",
    "dir": "ltr",
    "iso": "tr-TR",
    "name": "Turc",
    "files": [
      {
        "path": "i18n/locales/tr-TR.json"
      }
    ]
  },
  {
    "code": "de",
    "dir": "ltr",
    "iso": "de-DE",
    "name": "German",
    "files": [
      {
        "path": "i18n/locales/de-DE.json"
      }
    ]
  },
  {
    "code": "fr",
    "dir": "ltr",
    "iso": "fr-FR",
    "name": "Français",
    "files": [
      {
        "path": "i18n/locales/fr-FR.json"
      }
    ]
  },
  {
    "code": "es",
    "dir": "ltr",
    "iso": "es-ES",
    "name": "Spanish",
    "files": [
      {
        "path": "i18n/locales/es-ES.json"
      }
    ]
  },
  {
    "code": "xx",
    "dir": "ltr",
    "iso": "xx-XX",
    "name": "Translations",
    "files": [
      {
        "path": "i18n/locales/xx-XX.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
