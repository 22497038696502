type HeaderApparitionOptions = {
  threshold: number;
  disabled: boolean;
};

export function useHeaderScrollApparition(options: HeaderApparitionOptions) {
  const lastScrollPosition = ref(0);
  const visible = ref(true);

  onMounted(() => {
    window.addEventListener('scroll', onScroll);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll);
  });

  const onScroll = () => {
    if (options.disabled) {
      visible.value = true;
      return;
    }

    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;

    if (currentScrollPosition <= 0) {
      visible.value = true;
      lastScrollPosition.value = 0;
      return;
    }

    const distanceScrolled = Math.abs(
      currentScrollPosition - lastScrollPosition.value,
    );
    const scrollToTop =
      Math.sign(currentScrollPosition - lastScrollPosition.value) === -1;

    if (distanceScrolled < options.threshold) return;

    lastScrollPosition.value = currentScrollPosition;
    visible.value = scrollToTop;
  };

  const visibleComputed = computed(() => visible.value);
  const isScrolled = computed(() => lastScrollPosition.value > 0);

  return { visible: visibleComputed, scrolled: isScrolled };
}
