<template>
  <component
    :is="asLink ? 'a' : 'button'"
    :class="buttonClasses"
    :disabled="disabled"
    :target="target"
    :rel="asLink && target === '_blank' ? 'noopener noreferrer' : ''"
    :href="url"
    :type="type"
    :data-testid="$attrs['data-testid']"
    @click="handleClick">
    <template v-if="!loading">
      <span
        v-if="iconLeft || $slots.left"
        class="AiButton-icon AiButton-icon--left"
        :class="{ 'AiButton-icon--light': light }">
        <slot name="left">
          <ai-icon v-if="iconLeft" :size="size" :name="iconLeft" />
        </slot>
      </span>

      <ai-typo
        v-if="label"
        class="AiButton-label"
        :class="{
          'AiButton-label--light': light,
          'AiButton-label--lowercase': lowercase,
          'AiButton-label--uppercase': uppercase,
          'AiButton-label--text-left': labelAlign === 'left',
        }"
        :variant="typoVariant"
        as="span"
        :color="typoColor">
        {{ label }}
      </ai-typo>
      <slot v-else />

      <span
        v-if="iconRight || $slots.right"
        class="AiButton-icon AiButton-icon--right"
        :class="{ 'AiButton-icon--light': light }">
        <slot name="right">
          <ai-icon v-if="iconRight" :size="size" :name="iconRight" />
        </slot>
      </span>
    </template>

    <!-- Loader -->
    <div
      v-else
      class="AiButton-loader"
      role="alert"
      :aria-label="$t('accessibility.description.loading')">
      <div class="AiButton-loaderDot" />
      <div class="AiButton-loaderDot" />
      <div class="AiButton-loaderDot" />
    </div>
  </component>
</template>

<script setup lang="ts">
import { eventCustom } from '~/helpers';

import AiIcon from '../AiIcon/AiIcon.vue';
import type { IconName } from '../AiIcon/types';
import AiTypo from '../AiTypo/AiTypo.vue';
import type { AiTypoVariant, TypoColor } from '../AiTypo/types';

import { AiButtonVariant } from './constants';

interface AiButtonProps {
  disabled?: boolean;
  iconLeft?: IconName;
  iconRight?: IconName;
  inverse?: boolean;
  label?: string;
  light?: boolean;
  loading?: boolean;
  size?: number;
  slim?: boolean;
  small?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  typoVariant?: AiTypoVariant;
  typoColor?: TypoColor;
  fullWidth?: boolean;
  asLink?: boolean;
  url?: string;
  hoverEffect?: boolean;
  lowercase?: boolean;
  uppercase?: boolean;
  labelAlign?: 'left' | 'center';
  target?: '_blank' | '_self' | '_parent' | '_top';
  type?: HTMLButtonElement['type'];
}

const props = withDefaults(defineProps<AiButtonProps>(), {
  iconLeft: undefined,
  iconRight: undefined,
  inverse: false,
  label: undefined,
  size: undefined,
  lowercase: false,
  uppercase: true,
  labelAlign: undefined,
  target: undefined,
  hoverEffect: true,
  url: undefined,
  disabled: undefined,
  typoVariant: 'button-bold',
  typoColor: 'inherit',
  variant: AiButtonVariant.Primary,
  type: 'button',
});
const emits = defineEmits(['click']);

const buttonClasses = computed(() => {
  const classes = ['AiButton', `AiButton--${props.variant}`];

  if (props.inverse) classes.push('AiButton--inverse');
  if (props.small) classes.push('AiButton--small');
  if (props.slim) classes.push('AiButton--slim');
  if (props.fullWidth) classes.push('AiButton--fullWidth');
  if (props.light) classes.push('AiButton--light');
  if (!props.hoverEffect) classes.push('AiButton--noHoverEffect');

  return classes.join(' ');
});
const buttonHeight = computed(() =>
  props.size ? `${props.size / 10}rem` : undefined,
);

const handleClick = () => {
  eventCustom('Generic Event', {
    event_name: 'CTA_buttonclick',
    click_interaction: {
      event_info: props.variant,
      click_url: props.url,
      click_text: props.label,
      click_location: window.location.href,
      click_type: props.asLink ? 'a' : 'button',
    },
  });

  emits('click');
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/functions' as func;

.AiButton {
  position: relative;
  cursor: pointer;
  @include mixins.rem-fallback(min-height, 48);
  @include mixins.rem-fallback(padding-left, 24);
  @include mixins.rem-fallback(padding-right, 24);
  white-space: nowrap;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: v-bind(buttonHeight);
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
    color: colors.$neutral-500;
  }

  &--small {
    @include mixins.rem-fallback(min-height, 40);
  }

  &--slim {
    padding: 0;
  }

  &--primary {
    background-color: colors.$gold-900;
    color: colors.$white;
    fill: colors.$white;

    &:active:not(:disabled) {
      background-color: colors.$gold-600;
    }

    &:focus {
      &::after {
        position: absolute;
        display: block;
        content: '';
        border: 1px solid colors.$gold-900;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background-color: transparent;
      }
    }

    &:disabled {
      background-color: colors.$neutral-200;
      color: colors.$neutral-700;
    }

    &.AiButton--inverse {
      background-color: colors.$gold-300;
      color: colors.$stratos-900;
    }
  }

  &--secondary {
    background-color: transparent;
    color: colors.$gold-900;
    fill: colors.$gold-900;
    border: 1px solid colors.$gold-900;

    &:active:not(:disabled) {
      background-color: colors.$gold-600;
    }

    &:hover {
      background-color: colors.$gold-800;
      color: colors.$white;
      border: 1px solid colors.$gold-800;
    }

    &:focus {
      &::after {
        position: absolute;
        display: block;
        content: '';
        border: 1px solid colors.$gold-900;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background-color: transparent;
      }
    }

    &:disabled {
      border-color: colors.$neutral-500;
    }

    &.AiButton--inverse {
      color: colors.$gold-300;
      border: 1px solid colors.$gold-300;

      &:hover {
        background-color: colors.$gold-300;
        color: colors.$stratos-900;
        border: none;
      }

      &:active:not(:disabled) {
        background-color: colors.$gold-500;
        color: colors.$stratos-900;
        border: none;
      }

      &:focus {
        &::after {
          position: absolute;
          display: block;
          content: '';
          border: 1px solid colors.$gold-300;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
        }
      }

      &:disabled {
        color: colors.$neutral-300;
        border-color: colors.$neutral-300;
      }
    }
  }

  &--tertiary {
    @include mixins.rem-fallback(min-height, 0);

    padding-inline: 0;
    background-color: transparent;
    color: colors.$gold-900;
    fill: colors.$gold-900;
    @include mixins.rem-fallback(min-height, 0);

    &:active:not(:disabled) {
      color: colors.$gold-600;
    }

    &:hover {
      text-decoration: underline;
    }

    &.AiButton--inverse {
      color: colors.$gold-300;

      &:hover {
        color: colors.$gold-500;
      }

      &:active:not(:disabled) {
        color: colors.$gold-700;
      }

      &:focus {
        &::after {
          position: absolute;
          display: block;
          content: '';
          border-bottom: 1px solid colors.$gold-300;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
        }
      }

      &:disabled {
        color: colors.$neutral-300;
      }
    }
  }

  &--light {
    &.AiButton--primary {
      background-color: colors.$gold-300;
      color: colors.$stratos-900;
    }

    &.AiButton--secondary {
      color: colors.$gold-300;
      border-color: colors.$gold-300;
    }

    &.AiButton--tertiary {
      color: colors.$gold-300;
    }
  }

  &--fullWidth {
    width: 100%;
  }

  &--noHoverEffect {
    &:hover {
      text-decoration: none;
    }
  }
}

.AiButton-label {
  display: inline-block;

  &--text-left {
    text-align: left;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--lowercase {
    text-transform: lowercase;
  }
}

.AiButton-icon {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;

  &--left {
    margin-right: constants.$inner-02;
  }

  &--right {
    margin-left: constants.$inner-02;
  }

  &--light {
    fill: colors.$gold-300;
  }
}

.AiButton-loader {
  text-align: center;
}

.AiButton-loaderDot {
  display: inline-block;
  border-radius: 50%;
  margin-right: constants.$inner-00;
  margin-left: constants.$inner-00;
  @include mixins.rem-fallback(height, 4);
  @include mixins.rem-fallback(width, 4);
  transition: opacity 0.2s;
  background-color: colors.$white;
  animation-duration: 1s;
  animation-name: loader;
  animation-iteration-count: infinite;
  animation-delay: 0.3s;

  &:first-of-type {
    animation-delay: 0s;
  }

  &:last-of-type {
    animation-delay: 0.6s;
  }
}

@keyframes loader {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}
</style>
