<template>
  <div id="AiHeader-menu-myAccount" class="AccountSubmenu">
    <client-only>
      <account-submenu-logged
        v-if="userStore.isLogged"
        class="AccountSubmenu-logged"
        :hide-close="hideClose"
        :inverse="inverse"
        @close="$emit('close')" />
      <account-submenu-not-logged
        v-else
        class="AccountSubmenu-notLogged"
        :hide-close="hideClose"
        :inverse="inverse"
        @close="$emit('close')" />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '~~/domains/user';

const userStore = useUserStore();

type Props = {
  inverse?: boolean;
  hideClose?: boolean;
};

type Emits = {
  (event: 'close'): void;
};

defineProps<Props>();
defineEmits<Emits>();
</script>

<style lang="scss" scoped>
.AccountSubmenu {
  z-index: 102; // above the search bar
}
</style>
