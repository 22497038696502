/**
 * Get the raw backend URL depending on the context
 * If we are in the browser, use the public domain
 * Otherwise, use internal URL
 */
export function getRawBackendUrl() {
  const runtimeConfig = useRuntimeConfig();

  const serverUri = runtimeConfig.serverUri ?? runtimeConfig.public.baseUrl;

  if (!serverUri) return serverUri;

  return serverUri.endsWith('/') ? serverUri.slice(0, -1) : serverUri;
}
