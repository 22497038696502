<template>
  <div
    v-click-away="close"
    class="AiHeaderPicker"
    :class="{ 'AiHeaderPicker--inverse': inverse }"
    @keydown.esc="toggleAiHeaderPicker">
    <ai-button
      ref="toggleButton"
      variant="tertiary"
      class="AiHeaderPicker-button"
      :aria-controls="controls"
      :aria-expanded="openedValue"
      :data-testid="$attrs['data-testid']"
      @click="toggleAiHeaderPicker">
      <ai-typo v-if="label" as="span" class="sr-only">{{ label }} :</ai-typo>
      <ai-typo
        as="span"
        variant="overline-01"
        class="AiHeaderPicker-label"
        :data-testid="`${$attrs['data-testid']}.value`"
        :class="{ 'AiHeaderPicker-label--inverse': inverse }">
        {{ value }}
      </ai-typo>
      <ai-icon
        name="chevron-down"
        class="AiHeaderPicker-icon"
        :class="{
          'AiHeaderPicker-icon--inverse': inverse,
          'AiHeaderPicker-icon--opened': openedValue,
        }"
        :size="16" />
    </ai-button>

    <div
      :id="controls"
      class="AiHeaderPicker-menu"
      :class="{
        'AiHeaderPicker-menu--opened': openedValue,
        'AiHeaderPicker-menu--inverse': inverse,
      }">
      <slot name="menu" :close="close" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { directive as vClickAway } from '~~/directives/click-away';

type Props = {
  controls: string;
  modelValue: unknown;
  inverse?: boolean;
  label?: string;
  openedValue: boolean;
};

type Emits = {
  (event: 'update:model-value', value: Props['modelValue']): void;
  (event: 'update:openedValue', value: boolean): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const toggleButton = ref();

const close = () => {
  if (!props.openedValue) return;
  emits('update:openedValue', false);
};

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: Props['modelValue']) {
    emits('update:model-value', newValue);
  },
});

const toggleAiHeaderPicker = () => {
  const newValue = !props.openedValue;

  emits('update:openedValue', newValue);

  if (props.openedValue === false) {
    toggleButton.value?.$el.focus();
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/functions' as func;

.AiHeaderPicker {
  position: relative;
}

.AiHeaderPicker-button {
  display: flex;
  align-items: center;
  border-left: 1px solid colors.$neutral-200;
  padding-left: constants.$padding-02;
}

.AiHeaderPicker-label {
  text-transform: uppercase;
  color: colors.$stratos-900;
  transition: all 300ms;

  &--inverse {
    color: colors.$white;
  }
}

.AiHeaderPicker-icon {
  margin-left: constants.$inner-02;
  color: colors.$gold-700;
  transition: all 300ms;

  &--inverse {
    color: colors.$gold-300;
  }

  &--opened {
    transform: rotate(180deg);
  }
}

.AiHeaderPicker-menu {
  display: none;
  background-color: colors.$white;
  min-width: func.calcRem(124);
  width: min-content;
  max-height: 60vh;
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  left: constants.$inner-02;
  z-index: 1;
  border: 1px solid colors.$gold-200;
  transform: translateY(calc(100% + constants.$inner-01));

  &::-webkit-scrollbar {
    width: constants.$inner-01;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: colors.$gold-700;
  }

  &--opened {
    display: inherit;
  }

  &--inverse {
    background-color: colors.$neutral-800;
    border-color: colors.$gold-700;
  }
}
</style>
