export enum RedirectType {
  Booking = 'booking',
  Hotel = 'hotel',
  Search = 'search',
  BookingRetry = 'booking-retry',
  BookingConfirmation = 'booking-confirmation',
  ContinueBooking = 'continue-booking',
  BookingConfirmationError = 'booking-confirmation-error',
}

export interface RedirectQueryParameters {
  ridcode?: string;
  dateIn?: string;
  nights?: number;
  destination?: string;
  roomsAndGuests?: string;
  to?: string;
  bookingId?: string;
  status?: string;
}

export interface RedirectToBookingConfirmationsQueryParameters {
  bookingId: string;
  status: string;
}

export interface RedirectToBookingQueryParameters {
  ridcode: string;
  dateIn: string;
  nights?: number;
  roomsAndGuests: string;
}

export interface RedirectToHotelQueryParameters {
  ridcode: string;
  roomsAndGuests?: string;
}

export interface RedirectToSearchQueryParameters {
  ridcode?: string;
  dateIn?: string;
  nights?: number;
  destination?: string;
  roomsAndGuests?: string;
}

export interface ParsedRoomsAndGuestsParameter {
  adults: number;
  childrenAges: number[];
}

export interface ParsedDestinationParameter {
  text: string;
  type: string;
}

export interface HotelByRidcodeResponse {
  exists: boolean;
  hotel: {
    id: string;
    name: string;
    slug: string;
  };
}

export type LocalePathFunction = (
  path: string | { name: string },
  locale?: string,
) => string;
