import {
  DEFAULT_LANGUAGE,
  ENABLED_LANGUAGES,
  SupportedLanguages,
} from '~~/core';
import { useBookingUrlGenerator } from '~~/domains/booking';
import { getRawBackendUrl } from '~~/helpers/backend-url.helper';
import { getRedirectType, getRedirectUrl } from '~~/helpers/redirect.helper';
import type { RedirectQueryParameters } from '~~/helpers/redirect.interface';

export default defineNuxtRouteMiddleware(async to => {
  const localePath = useLocalePath();
  const serverUrl = getRawBackendUrl();
  const { bookingURL } = useBookingUrlGenerator();

  if (to.path.includes('/redirect')) {
    const queryParameters: RedirectQueryParameters = to.query;

    const redirectType = await getRedirectType(serverUrl, queryParameters);
    const url = await getRedirectUrl(
      redirectType,
      serverUrl,
      localePath,
      bookingURL,
      queryParameters,
    );

    if (!url) {
      return navigateTo(localePath('/'));
    }

    const regex = /^\/([a-z]{2})/;
    const matches = regex.exec(url);

    if (matches?.length) {
      const [, language] = matches;

      if (
        !language ||
        !ENABLED_LANGUAGES.includes(language as SupportedLanguages)
      ) {
        // I don't know why, but the second parameter to [localePath] doesn't work...
        // So we manually replace the language in the URL
        const newLocaleUrl = localePath(url).replace(
          regex,
          `/${DEFAULT_LANGUAGE}`,
        );
        return navigateTo(newLocaleUrl);
      }
    }

    return navigateTo(url);
  }
});
