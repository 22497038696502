<template>
  <div
    class="AiRadio"
    @click="input?.click"
    @focus="input?.focus"
    @blur="input?.blur">
    <input
      :id="value as string"
      ref="input"
      v-model="radioValue"
      :checked="radioValue"
      :name="inputName"
      class="AiRadio-input"
      :disabled="disabled"
      :aria-label="ariaLabel"
      type="radio" />
    <div
      class="AiRadio-element"
      @click="input?.click"
      @focus="input?.focus"
      @blur="input?.blur" />

    <ai-typo
      v-if="label"
      as="label"
      class="AiRadio-label"
      :for="value"
      variant="paragraph-03-regular">
      {{ label }}
    </ai-typo>

    <slot />
  </div>
</template>

<script setup lang="ts">
type Props = {
  modelValue?: string;
  value: string;
  inputName?: string;
  label?: string;
  disabled?: boolean;
  ariaLabel?: string;
};

type Emits = {
  (event: 'update:modelValue', value: Props['modelValue']): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const input = ref<HTMLInputElement>();

const radioValue = computed({
  get() {
    return props.modelValue === props.value;
  },
  set() {
    emits('update:modelValue', props.value);
  },
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';

.AiRadio {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: constants.$inner-02;
}

.AiRadio-input {
  position: absolute;
  opacity: 0;
}

.AiRadio-element {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  @include mixins.rem-fallback(height, 24);
  @include mixins.rem-fallback(width, 24);
  border: 2px solid colors.$gold-800;
  border-radius: 100%;
}

.AiRadio-input:checked + .AiRadio-element {
  &::after {
    content: '';
    position: absolute;
    @include mixins.rem-fallback(height, 8);
    @include mixins.rem-fallback(width, 8);
    border-radius: 100%;
    background-color: colors.$gold-800;
    @include mixins.rem-fallback(left, 6);
    @include mixins.rem-fallback(top, 6);
  }
}

.AiRadio-input:focus + .AiRadio-element {
  &::before {
    content: '';
    position: absolute;
    @include mixins.rem-fallback(top, -5);
    @include mixins.rem-fallback(left, -5);
    @include mixins.rem-fallback(height, 30);
    @include mixins.rem-fallback(width, 30);
    border: 2px solid colors.$gold-800;
    border-radius: 100%;
  }
}

.AiRadio-input:disabled + .AiRadio-element {
  border-color: colors.$neutral-400;

  &::before {
    border-color: colors.$neutral-400;
  }

  &::after {
    background-color: colors.$neutral-400;
  }
}

.AiRadio-label {
  display: inline-block;
  flex-grow: 1;
}
</style>
