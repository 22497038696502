import { CountryToCurrencyMapping, DEFAULT_CURRENCY } from '~~/core';

export function useCurrencyFromLocalization() {
  const rawLocalization = useLocalizationHeader();

  const countryLocalization = computed(
    () =>
      rawLocalization.value?.toUpperCase() as keyof typeof CountryToCurrencyMapping,
  );

  return (
    CountryToCurrencyMapping[countryLocalization.value] || DEFAULT_CURRENCY
  );
}
