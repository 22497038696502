import { addDays, format } from 'date-fns';
import { defineStore } from 'pinia';

import {
  ECommerceData,
  ECommerceDataItem,
  extractDateFromApiString,
  FORMATS,
} from '~/helpers';

import type { Basket, SelectedRoom } from '../graphql';

import { PaymentMode } from './enums';
import { computeAdultsAndChildren } from './helpers';
import type {
  Beneficiary,
  PromotionalInformation,
  RawBookingQueryParameters,
  Room,
  UnconfiguredGuests,
} from './interfaces';
import {
  serializeCurrentRoom,
  serializeRemainingGuests,
  serializeRooms,
  serializeSpecialOptions,
} from './serializers';

type HotelDetails = {
  city?: string;
  cityCode?: string;
  country?: string;
  countryCode?: string;
  hotelName?: string;
  brandCode?: string;
};

export type State = {
  _hotelDetails: HotelDetails | null;
  additionalInformation: string;
  basketId?: string | null;
  beneficiary: Beneficiary | null;
  burntPoints: number;
  currency?: string | null;
  currentRoom: Partial<Room> | null;
  currentBasket: Basket | null;
  dates: {
    dateIn: string | null;
    nights: number;
  };
  hotelId: string | null;
  loading: boolean;
  maxChildrenAge: number;
  numberOfRooms: number;
  remainingGuests: Array<UnconfiguredGuests>;
  paymentMode?: PaymentMode;
  promotionalInformation: PromotionalInformation;
  rooms: Array<Room>;
  specialOptions: string[];
  tripType?: string;
  updateBeneficiaryAccount: boolean;
  asAnonymous: boolean | null;
  wantSignup: boolean;
  hasOffersWithPromoCode: boolean | null | undefined;
  roomResultTrigger: number;
  preferentialCode: string;
};

export type Getters = {
  currentCurrency(): string;
  childAsAdult(): number;
  areAllRoomsConfigured(): boolean;
  hotelDetails(): HotelDetails;
  routeParameters(): RawBookingQueryParameters;
  selectedRooms(): (isLogged: boolean) => SelectedRoom[];
  totalGuestsCount(): number;
  nbAdults(): number;
  nbChildren(): number;
  currentUnconfiguredGuestsCount(): number;
  isoDateIn(): string | null;
  isoDateOut(): string | null;
  nights(): number;
  totalAdultsRemainingCount(): number;
  totalChildrenRemainingCount(): number;
  ecommerceEvent(): ECommerceData;
};

export type Actions = {
  addRoom(room: Room): void;
  commitRoom(): void;
  setCurrency(currency: string | null | undefined): void;
  updateCurrentRoom(room: Partial<Room>): void;
  setHasOffersWithPromoCode(value: boolean | null | undefined): void;
};

const determineOfferWithUser = (room: Room, isLogged: boolean) => {
  if (isLogged && room.memberOfferId) {
    return room.memberOfferId;
  }

  return room.publicOfferId;
};

export const useBookingStore = defineStore<'booking', State, Getters, Actions>(
  'booking',
  {
    actions: {
      addRoom(room) {
        this.rooms.push(room);
      },
      commitRoom() {
        if (!this.currentRoom) return;
        if (!this.currentRoom.productCode || !this.currentRoom.publicOfferId)
          return;

        const guests = this.remainingGuests.shift();

        if (!guests) return;

        const newRoom = Object.assign({}, this.currentRoom, {
          adults: guests.adults,
          childrenAges: guests.childrenAges,
        }) as Room;

        if (this.rooms.length >= this.numberOfRooms) {
          this.rooms.splice(this.rooms.length - 1, 1, newRoom);
        } else {
          this.rooms.push(newRoom);
        }

        this.currentRoom = null;
      },
      setCurrency(currency) {
        const userCurrencyCookie = useCookie('userCurrency', {
          httpOnly: false,
        });

        this.currency = currency;
        userCurrencyCookie.value = currency as string | null;
      },
      updateCurrentRoom(room) {
        if (!this.currentRoom) this.currentRoom = room;
        else Object.assign(this.currentRoom, room);
      },
      setHasOffersWithPromoCode(value) {
        this.hasOffersWithPromoCode = value;
      },
    },
    getters: {
      totalAdultsRemainingCount() {
        return this.remainingGuests.reduce((total, room) => {
          return total + room.adults;
        }, 0);
      },
      totalChildrenRemainingCount() {
        return this.remainingGuests.reduce((total, room) => {
          return total + room.childrenAges.length;
        }, 0);
      },
      currentUnconfiguredGuests() {
        if (!this.remainingGuests.length) return 0;

        return (
          this.remainingGuests[0].adults +
          this.remainingGuests[0].childrenAges.length
        );
      },
      childAsAdult() {
        if (!this.maxChildrenAge) return 0;

        if (
          this.remainingGuests.length === 0 ||
          !Array.isArray(this.remainingGuests[0].childrenAges)
        )
          return 0;

        return this.remainingGuests[0].childrenAges.reduce(
          (count, childrenAge) => {
            if (childrenAge > this.maxChildrenAge) {
              count++;
            }

            return count;
          },
          0,
        );
      },
      areAllRoomsConfigured() {
        return this.numberOfRooms <= this.rooms.length;
      },
      nbChildren() {
        return this.rooms.reduce(
          (acc, room) => acc + room.childrenAges.length,
          0,
        );
      },
      currentCurrency(): string {
        const localizedCurrency = useCurrencyFromLocalization();

        return this.currency || this.beneficiary?.currency || localizedCurrency;
      },
      nbAdults(): number {
        return (
          this.rooms.reduce((count, room) => count + (room.adults ?? 0), 0) ?? 2
        );
      },
      hotelDetails(): HotelDetails {
        return this._hotelDetails ?? {};
      },
      routeParameters() {
        const parameters: RawBookingQueryParameters = {
          dateIn: this.dates.dateIn as string,
          nights: String(this.dates.nights),
          ridcode: this.hotelId as string,
          roomCount: String(this.numberOfRooms),
        };

        if (this.asAnonymous !== null) {
          parameters.asAnonymous = String(this.asAnonymous);
        }

        if (this.wantSignup) {
          parameters.wantSignup = 'true';
        }

        if (this.rooms.length > 0) {
          serializeRooms(this.rooms, parameters);
        }

        if (this.remainingGuests.length) {
          serializeRemainingGuests(this.remainingGuests, parameters);
        }

        if (this.currentRoom) {
          serializeCurrentRoom(this.currentRoom, parameters);
        }

        if (this.additionalInformation) {
          parameters.additionalInformation = this.additionalInformation;
        }

        if (
          Array.isArray(this.specialOptions) &&
          this.specialOptions.length > 0
        ) {
          serializeSpecialOptions(this.specialOptions, parameters);
        }

        if (this.preferentialCode) {
          parameters.preferentialCode = this.preferentialCode;
        }

        return parameters;
      },
      selectedRooms() {
        return isLogged => {
          return this.rooms.map(room => {
            const { adults, childrenAges } = computeAdultsAndChildren(
              room.adults,
              room.childrenAges || [],
              this.maxChildrenAge,
            );

            return {
              adults,
              childrenAges,
              offerCode: determineOfferWithUser(room, isLogged),
              period: {
                dateIn: this.dates.dateIn,
                nights: this.dates.nights,
              },
              productCode: room.productCode,
            };
          });
        };
      },
      totalGuestsCount() {
        const guestsInRoom = this.rooms.reduce((total, room) => {
          return total + room.adults + (room.childrenAges.length || 0);
        }, 0);

        const remainingGuests = this.remainingGuests.reduce((total, room) => {
          return total + room.adults + (room.childrenAges.length || 0);
        }, 0);

        return guestsInRoom + remainingGuests;
      },
      currentUnconfiguredGuestsCount() {
        if (this.remainingGuests.length === 0) return 0;

        return (
          this.remainingGuests[0].adults +
          this.remainingGuests[0]?.childrenAges.length
        );
      },
      isoDateIn() {
        return this.dates.dateIn
          ? extractDateFromApiString(this.dates.dateIn).toISOString()
          : null;
      },
      isoDateOut() {
        if (!this.isoDateIn) return null;

        const dateIn = new Date(this.isoDateIn);
        const dateOut = addDays(dateIn, this.nights);

        return format(dateOut, FORMATS.apiFormat);
      },
      nights() {
        return this.dates.nights ?? 0;
      },
      ecommerceEvent() {
        const rooms = this.currentBasket?.rooms || [];
        const basketRooms = rooms.reduce((acc, basketRoom) => {
          const basketRoomPrice =
            basketRoom?.offer?.pricing?.amount?.afterTax || 0;

          const roomIndex = acc.findIndex(
            room => room.item_variant === basketRoom?.productCode,
          );

          if (roomIndex >= 0) {
            (acc[roomIndex].quantity as number)++;

            return acc;
          }

          acc.push({
            item_id: this.hotelId || '',
            item_name: this.hotelDetails.hotelName || '',
            item_category: 'room',
            item_category2: basketRoom?.offer?.code || '',
            item_category3: basketRoom?.offer?.label || '',
            item_category4: this.hotelDetails.city || '',
            item_brand: this.hotelDetails.brandCode || '',
            item_variant: basketRoom?.productCode || '',
            quantity: 1,
            night_nb: basketRoom?.period?.nights || 0,
            location_id: this.hotelDetails.countryCode || '',
            price: basketRoomPrice,
          });

          return acc;
        }, [] as ECommerceDataItem[]);

        return {
          currency:
            this.currentBasket?.prices?.currency ?? this.currentCurrency,
          value: this.currentBasket?.prices?.details?.afterTax?.toString(),
          location_id: this.hotelDetails.countryCode,
          arrival_date: this.dates.dateIn as string,
          departure_date: addDays(
            new Date(this.dates.dateIn as string),
            this.nights,
          )
            .toISOString()
            .split('T')[0],
          room_nb: this.rooms.length,
          night_nb: this.nights,
          adults_nb: this.nbAdults,
          children_nb: this.nbChildren,
          items: basketRooms,
        };
      },
    },
    state() {
      return {
        additionalInformation: '',
        basketId: null,
        beneficiary: null,
        burntPoints: 0,
        remainingGuests: [],
        currency: useCookie('userCurrency').value,
        currentRoom: null,
        currentBasket: null,
        dates: {
          dateIn: null,
          nights: 0,
        },
        _hotelDetails: null,
        hotelId: null,
        loading: false,
        maxChildrenAge: 16,
        numberOfGuests: 0,
        numberOfRooms: 0,
        paymentMode: undefined,
        promotionalInformation: {},
        rooms: [],
        specialOptions: [],
        tripType: 'leisure',
        updateBeneficiaryAccount: false,
        asAnonymous: null,
        wantSignup: false,
        hasOffersWithPromoCode: null,
        roomResultTrigger: 0,
        preferentialCode: '',
      };
    },
  },
);
