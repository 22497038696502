<template>
  <ai-icon
    class="AiLogo"
    :class="[`AiLogo--${variant}`]"
    :name="name"
    :size="computedSize"
    role="img"
    :aria-label="title" />
</template>

<script lang="ts" setup>
import AiIcon from '../AiIcon/AiIcon.vue';
import type { LogoName } from '../AiIcon/types';

import { getBrandLogoName, getBrandName } from '~~/helpers';

export type BrandCode = 'FAI' | 'RIX' | 'SWI' | 'AAI' | 'ALI';
export interface AiLogoProps {
  brand?: BrandCode | string | null;
  size?:
    | 's'
    | 'l'
    | number
    | {
        height: number;
        width: number;
      };
  variant?: 'positive' | 'inverse';
}

const props = withDefaults(defineProps<AiLogoProps>(), {
  brand: undefined,
  size: () => ({ height: 50, width: 172 }),
  variant: 'positive',
});

const name = computed<LogoName>(() => getBrandLogoName(props.brand));

const title = computed(() => getBrandName(props.brand));

const computedSize = computed(() => {
  switch (props.size) {
    case 's':
      return { height: 32, width: 116 };
    case 'l':
      return { height: 50, width: 172 };
    default:
      return props.size;
  }
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/colors';

.AiLogo {
  color: colors.$stratos-900;

  &--inverse {
    color: colors.$white;
  }
}
</style>
