type When = 'page:finish' | 'manual';

function scrollToTop() {
  if (process.server) return;

  window.scrollTo({ top: 0 });
}

function unregisterListeners(listeners: Array<() => void>) {
  for (const listener of listeners) {
    if (typeof listener !== 'function') continue;

    listener();
  }
}

type UseScrollToTop = {
  scrollToTop: typeof scrollToTop;
  unregisterScrollToTop: () => void;
};

export function useScrollToTop(cases: When[] = ['manual']): UseScrollToTop {
  const listeners: Array<() => void> = [];
  const nuxtApp = useNuxtApp();

  for (const when of cases) {
    if (when === 'page:finish') {
      const listener = nuxtApp.hook('page:finish', scrollToTop);
      listeners.push(listener);
    }
  }

  const unregisterScrollToTop = () => {
    unregisterListeners(listeners);
    listeners.splice(0, listeners.length);
  };

  return { scrollToTop, unregisterScrollToTop };
}
