import { useBookingStore } from '~~/domains/booking';

export default defineNuxtRouteMiddleware(to => {
  const bookingStore = useBookingStore();

  if (!to.name?.toString().includes('booking')) {
    const currency = bookingStore.currency;

    bookingStore.$reset();

    bookingStore.currency = currency;
  }
});
