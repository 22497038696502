import type { PaymentModeName } from '../ux/atoms/AiIcon/types';

import { PaymentMode } from './enums';

import { Environment } from '~~/core';

export const PaymentModeIconMapping: Record<PaymentMode, PaymentModeName> = {
  [PaymentMode.AmericanExpress]: 'american-express',
  [PaymentMode.BancContact]: 'banc-contact',
  [PaymentMode.BlueCard]: 'blue-card',
  [PaymentMode.ChinaUnionPay]: 'china-union-pay',
  [PaymentMode.DinersClub]: 'diners-club',
  [PaymentMode.Elo]: 'elo',
  [PaymentMode.JBC]: 'JBC',
  [PaymentMode.Maestro]: 'maestro',
  [PaymentMode.Mastercard]: 'mastercard',
  [PaymentMode.PostFinance]: 'postfinance',
  [PaymentMode.RuPay]: 'rupay',
  [PaymentMode.Visa]: 'visa',
};

/**
 * The duration in minutes to refresh the booking prices.
 */
export const REFRESH_PRICES_DURATION_MAP = new Map<Environment, number>([
  [Environment.Dev, 15],
  [Environment.Rec, 15],
  [Environment.Oat, 15],
  [Environment.Pro, 15],
  [Environment.Test, 15],
]);

export const BASKET_AUTHENTICATION_STATUS = {
  Logged: 'LOGGED',
  Anonymous: 'ANONYMOUS',
} as const;

export const AllInclusivePlan = {
  Full: 'FULL',
  Partial: 'PARTIAL',
  None: 'NONE',
} as const;

export const COOKIE_BASKET_ID = 'basketID';
